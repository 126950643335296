import img_1 from '../assets/images/obatImages/1.jpg';
import img_2 from '../assets/images/obatImages/2.jpg';
import img_3 from '../assets/images/obatImages/3.jpg';
import img_4 from '../assets/images/obatImages/4.jpg';
import img_5 from '../assets/images/obatImages/5.jpg';
import img_6 from '../assets/images/obatImages/6.jpg';
import img_7 from '../assets/images/obatImages/7.jpg';
import img_8 from '../assets/images/obatImages/8.jpg';
import img_9 from '../assets/images/obatImages/9.jpg';
import img_10 from '../assets/images/obatImages/10.jpg';
import img_11 from '../assets/images/obatImages/11.jpg';
import img_12 from '../assets/images/obatImages/12.jpg';
import img_13 from '../assets/images/obatImages/13.jpg';
import img_14 from '../assets/images/obatImages/14.jpg';
import img_15 from '../assets/images/obatImages/15.jpg';
import img_16 from '../assets/images/obatImages/16.jpg';
import img_17 from '../assets/images/obatImages/17.jpg';
import img_18 from '../assets/images/obatImages/18.jpg';
import img_19 from '../assets/images/obatImages/19.jpg';
import img_20 from '../assets/images/obatImages/20.jpg';
import img_21 from '../assets/images/obatImages/21.jpg';
import img_22 from '../assets/images/obatImages/22.jpg';
import img_23 from '../assets/images/obatImages/23.jpg';
import img_24 from '../assets/images/obatImages/24.jpg';
import img_25 from '../assets/images/obatImages/25.jpg';
import img_26 from '../assets/images/obatImages/26.jpg';
import img_27 from '../assets/images/obatImages/27.jpg';
import img_28 from '../assets/images/obatImages/28.jpg';
import img_29 from '../assets/images/obatImages/29.jpg';
import img_30 from '../assets/images/obatImages/30.jpg';
import img_31 from '../assets/images/obatImages/31.jpg';
import img_32 from '../assets/images/obatImages/32.jpg';
import img_33 from '../assets/images/obatImages/33.jpg';
import img_34 from '../assets/images/obatImages/34.jpg';
import img_35 from '../assets/images/obatImages/35.jpg';
import img_36 from '../assets/images/obatImages/36.jpg';
import img_37 from '../assets/images/obatImages/37.jpg';
import img_38 from '../assets/images/obatImages/38.jpg';
import img_39 from '../assets/images/obatImages/39.jpg';
import img_40 from '../assets/images/obatImages/40.jpg';
import img_41 from '../assets/images/obatImages/41.jpg';
import img_42 from '../assets/images/obatImages/42.jpg';
import img_43 from '../assets/images/obatImages/43.jpg';
import img_44 from '../assets/images/obatImages/44.jpg';
import img_45 from '../assets/images/obatImages/45.jpg';
import img_46 from '../assets/images/obatImages/46.jpg';
import img_47 from '../assets/images/obatImages/47.jpg';
import img_48 from '../assets/images/obatImages/48.jpg';
import img_49 from '../assets/images/obatImages/49.jpg';
import img_50 from '../assets/images/obatImages/50.jpg';
import img_51 from '../assets/images/obatImages/51.jpg';
import img_52 from '../assets/images/obatImages/52.jpg';
import img_53 from '../assets/images/obatImages/53.jpg';
import img_54 from '../assets/images/obatImages/54.jpg';
import img_55 from '../assets/images/obatImages/55.jpg';
import img_56 from '../assets/images/obatImages/56.jpg';
import img_57 from '../assets/images/obatImages/57.jpg';
import img_58 from '../assets/images/obatImages/58.jpg';
import img_59 from '../assets/images/obatImages/59.jpg';
import img_60 from '../assets/images/obatImages/60.jpg';
import img_61 from '../assets/images/obatImages/61.jpg';
import img_62 from '../assets/images/obatImages/62.jpg';
import img_63 from '../assets/images/obatImages/63.jpg';
import img_64 from '../assets/images/obatImages/64.jpg';
import img_65 from '../assets/images/obatImages/65.jpg';
import img_66 from '../assets/images/obatImages/66.jpg';
import img_67 from '../assets/images/obatImages/67.jpg';
import img_68 from '../assets/images/obatImages/68.jpg';
import img_69 from '../assets/images/obatImages/69.jpg';
import img_70 from '../assets/images/obatImages/70.jpg';
import img_71 from '../assets/images/obatImages/71.jpg';
import img_72 from '../assets/images/obatImages/72.jpg';
import img_73 from '../assets/images/obatImages/73.jpg';
import img_74 from '../assets/images/obatImages/74.jpg';
import img_75 from '../assets/images/obatImages/75.jpg';


const db_obat = [
  {
    "No": 1,
    "Kategori": "Antibiotik",
    "NamaObat": "AMOXICILLIN",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_1,
    "Deskripsi": "AMOXICILLIN merupakan obat antibiotik generik turunan Penisilin dengan aktivitas antibakteri spektrum luas. Obat ini bersifat bakterisid yang efektif terhadap bakteri Gram negatif dan Gram positif seperti Staphylococci, Streptococci, Enterococci, S. pneumoniae, N. gonorrhoeae, H influenzas, E. coli, dan P. mirabiis. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi saluran napas, saluran genito-urinaria, kulit & jaringan lunak yang disebabkan organisme Gram positif & Gram Negatif yang peka terhadap Amoxicillin",
    "Komposisi": "Amoxicillin 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa 250 sampai 500 mg tiap 8 jam. \nAnak 25 mg/kgBB/hari terbagi tiap 8 jam. \nInfeksi berat diberikan dosis ganda. Jika akut diberikan dalam 2 sampai 3 g dosis tunggal.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitif terhadap penisilin. Infeksi mononukleosis",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Alergi terhadap Penisilin atau Sefalosporin, gangguan ginjal, leukemia limfatik, ibu hamil dan menyusui.",
    "EfekSamping": "Reaksi hipersensitif. gangguan saluran pencernaan, seperti: mual, muntah, dan diare.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 2,
    "Kategori": "Antibiotik",
    "NamaObat": "AMOXICILLIN FORTE",
    "Kemasan": "250 MG/5 ML",
    "BentukObat": "Sirup Kering",
    "Golongan": "Obat Keras",
    "GambarObat": img_2,
    "Deskripsi": "AMOXICILLIN SIRUP merupakan obat antibiotik generik turunan Penisilin dengan aktivitas antibakteri spektrum luas. Obat ini digunakan pada infeksi saluran nafas atas, otitis media, bronkitis akut dan kronik, pneumonia, sistitis, uretritis, pielonefritis, bakteriuria asimptomatik pada masa hamil, gonore, infeksi kulit dan jaringan lunak. Amoxicillin bersifat bakterisid dan memiliki spektrum mirip dengan ampisilin. Obat ini efektif terhadap bakteri Gram negatif dan Gram positif seperti Staphylococci, Streptococci, Enterococci, S. pneumoniae, N. gonorrhoeae, H influenzas, E. coli, dan P. mirabiis. Bekerja dengan berikatan dengan penicillin-binding protein yang menghambat proses transpeptidation (proses cross-linking di sintesis dinding sel), mengaktifkan enzim autolisis pada dinding sel bakteri. Proses ini menyebabkan lisis dari dinding sel dan penghancuran sel bakteri. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi saluran nafas atas, infeksi saluran nafas bawah, infeksi tulang dan sendi, infeksi gigi, sepsis, saluran genito-urinaria, infeksi kulit dan jaringan lunak yang disebabkan organisme Gram positif dan Gram negatif yang peka terhadap obat ini",
    "Komposisi": "Per 5 mL : Amoxicillin 250 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dewasa : 250-500 mg tiap 8 jam. Infeksi berat : dosis ganda.. Anak : 25 mg/kg/hari dalam dosis terbagi setiap 8 jam. Anak usia kurang dari 1 tahun : 1 mL, 3 kali sehari. Anak usia 1 - 6 tahun (10 - 18 kg) : 2,5 mL, 3 kali sehari. Anak usia di atas 6 tahun (18 - 40 kg) : 5 mL, 3 kali sehari. Dewasa : 10 ml, 3 kali sehari",
    "AturanPakai": "Tambahkan air minum (tidak panas atau dingin) sedikit demi sedikit sampai batas panah sambil dikocok hingga larut sempurna.",
    "KontraIndikasi": "Hipersensitif terhadap penisilin",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Hipersensitif terhadap sefalosporin. \n- Gagal ginjal. \n- Leukemia limfatik. \n- Superinfeksi.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah : Ruam, diare, mual, muntah.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 3,
    "Kategori": "Antibiotik",
    "NamaObat": "ERITROMICIN",
    "Kemasan": "250 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Keras",
    "GambarObat": img_3,
    "Deskripsi": "-",
    "IndikasiUmum": "Untuk mencegah infeksi saluran nafas bagian atas terutama yang disebabkan oleh kuman S. pyogenes (streptokokus grup A beta-hemolitik)",
    "Komposisi": "ERYTHROMYCIN",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 250-500 mg, 4 kali sehari. \nAnak-anak : 30-50 mg/kg berat badan dalam sehari. Dosis tersebut dibagi menjadi 4, 3, atau 2 dosis atau diberikan setiap 6, 8, atau 12 jam. \nAnak dibawah 2 tahun : 125 mg (2,5 ml), 4 kali sehari.\nAnak-anak usia 2-6 tahun : 250 mg (5 ml), 4 kali sehari.",
    "AturanPakai": "Dikonsumsi pada perut kosong (1 atau 2 jam sebelum/sesudah makan)",
    "KontraIndikasi": "Hipersensitivitas. Pasien menerima astemizole, terfenadine, cisapride, pimozide, ergotamine atau dihydroergotamine.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pasien dengan pernapjangan repolarisasi jantung dan interval QT, hipokalemi yang tidak terkoreksi atau hipomagnesemia, bradikardia yang bermakna secara klinis, miastenia gravis. Kerusakan hati. Kehamilan dan menyusui.",
    "EfekSamping": "Nyeri perut dan kram, mual, muntah, diare, stomatitis, nyeri ulu hati, anoreksia, melena, pruritus ani, pankreatitis akut ringan reversibel, disfungsi hati, perpanjangan interval QT, aritmia ventrikel, urtikaria, erupsi kulit, ruam, gangguan pendengaran bilateral, tinnitus , vertigo, iritasi vena, tromboflebitis.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 4,
    "Kategori": "Antibiotik",
    "NamaObat": "AZITROMICIN",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_4,
    "Deskripsi": "AZITHROMYCIN adalah obat antibiotik generik golongan makrolida yang aktivitasnya terhadap bakteri gram positif dan gram negatif. Obat ini bekerja dengan cara mengikat sub unit 50s dari ribosom bakteri sehingga menghambat translasi mRNA. Dengan demikian sistesis protein akan terganggu sehingga pertumbuhan bakteri akan terhambat. Obat ini digunakan untuk pengobatan infeksi yang disebabkan oleh H. influenzae, M. catarrhalis, S. pneumoniae, C. pneumoniae, H. influenzae, Streptococcus pyogenes, S. aureus, atau S. agalactiae. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi saluran napas atas & bawah, kulit & struktur kulit, uretritis & servisitis non GO krn Chlamydia trachomatis",
    "Komposisi": "Azithromycin 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER.\nDewasa : 500 mg 1 x/hari selama 3-5 hari. \nAnak 10 mg/kg BB/hari dosis tunggal selama 3-5 hari.",
    "AturanPakai": "Sesudah Makan",
    "KontraIndikasi": "hipersensitif, pasien dengan kerusakan hati.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Gangguan ginjal sedang atau berat, gangguan hati berat. Hamil & laktasi. Lansia. Hentikan pemakaian apabila terjadi reaksi alergi, gangguan hati. Pertumbuhan C. difficile. Kategori kehamilan:",
    "EfekSamping": "Mual, rasa tidak nyaman di perut, muntah, kembung, diare, gangguan pendengaran, nefritis interstisial, gangguan ginjal akut, fungsi hati abnormal, pusing/ vertigo, kejang, sakit kepala, somnolen, pemanjangan interval QT.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 5,
    "Kategori": "Antibiotik",
    "NamaObat": "CIPROFLOXACIN",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_5,
    "Deskripsi": "CIPROFLOXACIN merupakan obat antibiotik generik turunan Quinolon dengan aktivitas antibakteri spektrum luas. Obat ini bersifat bakterisid dan digunakan untuk pengobatan infeksi yang disebabkan oleh bakteri Gram positif dan Gram negatif yang sensitif terhadap ciprofloxacin seperti infeksi pada saluran kemih, saluran cerna, termasuk demam tifoid yang disebabkan oleh S. thypi, saluran napas (kecuali pneumonia akibat Streptococcus), kulit dan jaringan lunak, tulang dan sendi. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Obat ini digunakan untuk pengobatan infeksi yang disebabkan oleh bakteri Gram positif dan Gram negatif yang sensitif terhadap ciprofloxacin seperti infeksi pada saluran kemih, saluran cerna, termasuk demam tifoid.",
    "Komposisi": "Ciprofloxacin 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER.\nDewasa : 500-750 mg, 2 kali per hari selama 5-7 hari. \nAnak-anak: 10-20 mg/kgBB, 2 kali sehari, selama 5-7 hari, dosis maksimal 750 mg per kali pemberian",
    "AturanPakai": "Diminum sesudah makan",
    "KontraIndikasi": "Hipersensitif terhadap Ciprofloxacin dan Fluoroquinolon lain. Hamil dan menyusui. Penggunaan bersamaan dengan tizanidine.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hentikan penggunaan jika mengalami rasa nyeri, peradangan pada tendon dan ruptur rendah. Hati-hati pada gangguan fungsi ginjal, usia lanjut. Konsumsi air yang cukup untuk mencegah kristaluria.",
    "EfekSamping": "Mual, muntah, diare, nyeri perut, dispepsia; sakit kepala, pusing, ruam kulit, artralgia, peningkatan kadar kreatinin serum dan urea darah. Perubahan hematologi misalnya trombositopenia, leukopenia",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 6,
    "Kategori": "Antibiotik",
    "NamaObat": "CLINDAMYCIN",
    "Kemasan": "150 MG",
    "BentukObat": "Kapsul",
    "Golongan": "Obat Keras",
    "GambarObat": img_6,
    "Deskripsi": "CLINDAMYCIN 150 MG KAPSUL merupakan obat antibiotik yang bekerja menghambat sintesis protein dengan cara berikatan dengan ribosom 50s. Efek bakteriostatik atau bakterisidal clindamycin bergantung pada konsentrasi obat jenis organisme dan lokasi infeksi. obat ini aktif terhadap S. aureus, S. pneumoniae, S. pyogenes, S. anaerobic, S. viridans dan actinomycin isrealli. Clindamycin juga aktif terhadap bacteroides fragilis dan kuman anaerob lainnya. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi Gr - yang serius atau berat, disebabkan oleh organisme yang peka antara lain strep, pneumokokus dan staph, termasuk bakteri anaerob",
    "Komposisi": "Clindamycin 150 MG",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa: 150-300 mg tiap 6 jam. Dosis maksimum per kali minum 450 mg dan dosis maksimum perhari 1,8 g.\nAnak: 3-6 mg/kgBB tiap 6 jam. Bila berat badan anak kurang dari 10 kg, dosis yang diberikan minimal 37,5 mg tiap 8 jam.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitif terhadap klidamisin atau linkomisin",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Riwayat penyakit Gl, seperti kolitis, penyakit ginjal dan atau hati, superinfeksi dan pertumbuhan jamur berlebih, hamil",
    "EfekSamping": "Diare yang kadang-kadang disertai kolistik akut (hentikan terapi), nyeri perut, gangguan Gl, reaksi kulit, ikterus, kelainan hematologi",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 7,
    "Kategori": "Antibiotik",
    "NamaObat": "KOTRIMOKSAZOL",
    "Kemasan": "480 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_7,
    "Deskripsi": "COTRIMOXAZOLE 480 MG TABLET adalah tablet antibiotik kombinasi trimethoprim 80 mg dan sulfamethoxazole 400 mg. Obat ini digunakan untuk menangani infeksi yang disebabkan oleh bakteri, seperti bronkitis, otitis media, dan infeksi saluran kemih. Selain itu, kotrimoksazol juga dapat digunakan untuk menangani dan mencegah Pneumocystis Carinii Pneumonia (PCP) pada pasien dengan daya tahan tubuh turun, seperti penderita HIV/AIDS. Obat ini bekerja dengan menghambat pembentukan DNA dan protein bakteri. Dalam penggunaan obat ini HARUS SESUAI PETUNJUK DOKTER.",
    "IndikasiUmum": "Mencegah proses agregasi trombosit pada pasien infark miokard dan pasien angina tidak stabil, serta mencegah serangan serebral iskemik sesaat.",
    "Komposisi": "Sulfametoksazol 400 MG\nTrimetroprim 80 MG",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa: 960 mg (2 tablet), 2 kali sehari. \nAnak usia 6–12 tahun: 480 mg (1 tablet), 2 kali sehari.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "hipersensitif, gangguan hati dan ginjal, wanita hamil, menyusui",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Gangguan fungsi hati. \n- Gangguan fungsi ginjal, dianjurkan banyak minum, minimal 1.5 liter sehari.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah : Mual, muntah dan ruam kulit. Pada penggunaan jangka panjang pernah dilaporkan adanya megaloblastik anemia dan hal ini dapat ditolerir dengan pengobatan asam folinat.",
    "KategoriKehamilan": "Kategori D: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 8,
    "Kategori": "Antibiotik",
    "NamaObat": "KOTRIMOKSAZOL",
    "Kemasan": "240 MG/5 ML",
    "BentukObat": "Suspensi",
    "Golongan": "Obat Keras",
    "GambarObat": img_8,
    "Deskripsi": "COTRIMOXAZOLE 240 MG/ 5ML SIRUP adalah obat antibiotik yang digunakan untuk mengobati berbagai jenis infeksi seperti infeksi saluran pencernaan, pernafasan, saluran kemih dan berbagai jenis infeksi lainnya. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi saluran pencernaan, pernafasan, saluran kemih dan berbagai jenis infeksi lainnya.",
    "Komposisi": "Trimethoprim 40 mg \nSulfamethoxazole 200 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis Anak:\n2-6 bulan :120 mg (2,5ml), 2 kali sehari. \n6 bulan-6 tahun :240 mg (5 ml), 2 kali sehari. \n6-12tahun :480 mg (10ml), 2 kali sehari.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Jangan menggunakan Cotrimoxazole syrup untuk pasien yang memiliki riwayat alergi antibiotik trimethoprim dan sulfamethoxazole, atau obat-obat golongan sulfonamide lainnya, untuk pasien dengan gangguan hati dan ginjal yang berat sebaiknya dihindari, wanita hamil terutama menjelang kelahiran, anak < 2 tahun (kecuali untuk pengobatan atau pencegahan pneumocytosis jiroveci (P. carinii) pada bayi dari usia empat minggu atau lebih)",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Hipersensitivitas. \n- Diskrasia darah.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Mual, muntah, ruam, diare , demam, gatal nyeri otot dan sendi. Reaksi alergi yang parah.",
    "KategoriKehamilan": "Kategori D: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 9,
    "Kategori": "Antibiotik",
    "NamaObat": "METRONIDAZOL",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_9,
    "Deskripsi": "METRONIDAZOLE 500 MG TABLET adalah obat generik antimikroba dengan aktivitas yang sangat baik terhadap bakteri anaerob dan protozoa. Obat ini digunakan untuk mengobati infeksi trichomonas vaginalis, bakterial vaginosis (Infeksi Gardnerella vaginalis) dan infeksi Entamoeba histolytica dan Giardia lamblia (penyakit Giardiasis). Obat ini juga digunakan untuk pembedahan dan sepsis ginekologi dengan aktivitas utama terhadap bakteri anaerob kolon, terutama Bacteroides fragilis. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Obat ini digunakan untuk pengobatan amubiasis, trikomoniasis, dan infeksi bakteri anaerob",
    "Komposisi": "Metronidazole 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 3 kali sehari 500 mg \nAnak: 7.5 mg/kgBB setiap 6 jam.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitivitas terhadap metronidazol, kehamilan trimester 1, menyusui, riwayat penyakit darah, gangguan SSP",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pemberian lebih dari 7 hari hendaknya disertai dengan pemeriksaan leukosit secara berkala. Bila ditemukan ataksia, kejang, atau gejala SSP lainnya, maka metronidazole harus segera dihentikan. Dosis perlu dikurangi pada pasien dengan penyakit obstruksi hati berat, sirosis hati, gangguan fungsi ginjal berat..",
    "EfekSamping": "Mual, sakit kepala, mulut kering, anoreksia, diare, nyeri epigastrum dan konstipasi, dapat menyebabkan kantuk.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 10,
    "Kategori": "Antibiotik",
    "NamaObat": "KLORAMFENIKOL",
    "Kemasan": "2%",
    "BentukObat": "Salep Kulit",
    "Golongan": "Obat Keras",
    "GambarObat": img_10,
    "Deskripsi": "Kloramfenikol 2% SALEP adalah obat yang digunakan untuk mengobati dan mencegah infeksi kulit yang disebabkan oleh bakteri. Obat ini mengandung chloramphenicol yaitu antibiotik yang digunakan secara luas pada infeksi bakteri, jenis bakteriostatik dengan menghambat sistesis protein dengan cara menghambat aktivitas peptidil transferase dari ribosom bakteri, secara spesifik mengikat residu A2451 dan A2452 dari 23s rRNA subunit ribosom 50s untuk mencegah terjadinya ikatan peptida. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi kulit yang disebabkan bakteri Gram positif dan Gram negatif yang sensiti terhadap kloramfenikol",
    "Komposisi": "Chloramphenicol 2%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nOleskan 3-4 kali/ hari",
    "AturanPakai": "oleskan pada tempat yang sakit",
    "KontraIndikasi": "hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER",
    "EfekSamping": "Reaksi alergi, superinfeksi.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Antibiotik"
  },
  {
    "No": 11,
    "Kategori": "Antivirus",
    "NamaObat": "ASIKLOVIR",
    "Kemasan": "200 MG & 400 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_11,
    "Deskripsi": "ACYCLOVIR TABLET adalah obat antivirus generik dengan kandungan Acyclovir yang digunakan untuk mengobati infeksi akibat virus seperti Varicella zoster dan Herpes simplex. Obat ini digunakan untuk terapi Herpes simplex tipe 1 dan 2, herpes genital, dan infeksi cacar air dan cacar api. Obat ini bekerja dengan menurunkan kemampuan virus dalam menggandakan diri, yaitu melalui penghambatan DNA polymerase dan replikasi DNA virus, sehingga mencegah pembentukan DNA virus tanpa mempengaruhi sel normal (sel tubuh). Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pengobatan infeksi herpes simpleks pada kulit dan selaput lendir, termasuk herpes genitalia awal dan rekuren. Pencegahan infeksi herpes simpleks berulang pada pasien imunokompeten. Profilaksis herpes simpleks pada pasien immunocompromised. Pengobatan herpes zoster.",
    "Komposisi": "Acyclovir 200 mg & Acyclovir 400 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER.\nDewasa: Saat pertama kali terkena, dosisnya adalah 200-800 mg sebanyak 5 kali per hari (setiap 4 jam) selama 5-10 hari. Untuk pencegahan, dosisnya adalah 200-400 mg, 4 kali per hari.\nAnak-anak berusia di bawah 2 tahun: Separuh dosis orang dewasa.\nAnak-anak berusia di atas 2 tahun: Sama dengan dosis orang dewasa.",
    "AturanPakai": "Dapat dikonsumsi setelah makan.",
    "KontraIndikasi": "Hipersensitivitas",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada pasien yang mengalami gangguan fungsi ginjal, lansia dengan gangguan pembersihan kreatinin, wanita hamil dan/atau menyusui. Kategori Kehamilan.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Gatal-gatal/skin rash. Gangguan saluran pencernaan, termasuk: mual, muntah, diare, dan nyeri abdominal. Peningkatan sementara enzim hati, peningkatan urea darah dan kreatinin, sakit kepala, reaksi neurologis dan fatigue.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 12,
    "Kategori": "Antivirus",
    "NamaObat": "ASIKLOVIR",
    "Kemasan": "5%",
    "BentukObat": "Salep",
    "Golongan": "Obat Keras",
    "GambarObat": img_12,
    "Deskripsi": "ACYCLOVIR CREAM merupakan obat generik yang mengandung zat aktif Acyclovir. Krim ini digunakan untuk mengatasi infeksi herpes simpleks pada kulit dan selaput lendir yang biasanya berupa luka melepuh yang terjadi disekitar bibir atau wajah. Selain itu, obat ini juga digunakan untuk mengobati herpes genital dan labial awal serta rekuren. ACYCLOVIR CREAM bekerja dengan menurunkan kemampuan virus dalam menggandakan diri, yaitu melalui penghambatan DNA polymerase dan replikasi DNA virus, sehingga mencegah pembentukan DNA virus tanpa mempengaruhi sel normal (sel tubuh). Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Mengatasi infeksi herpes simpleks pada kulit dan selaput lendir, termasuk herpes genital dal labial awal serta rekuren.",
    "Komposisi": "Acyclovir 5%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Oleskan 5 kali per hari tiap 4 jam tanpa dosis malam. Lanjutkan pengobatan setidaknya selama 5 hari, dalam beberapa kasus pengobatan dilanjutkan hingga 10 hari.",
    "AturanPakai": "Dioleskan pada area infeksi/yang sakit setelah kulit dibersihkan dan dikeringkan.",
    "KontraIndikasi": "Hipersensitivitas",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Jangan digunakan pada selaput lendir, seperti: mulut, mata, atau vaginal. Bukan untuk pencegahan infeksi herpes simples rekuren. Hati-hati penggunaan pada wanita hamil dan/atau menyusui, serta anak-anak. Kategori Kehamilan :",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Sensasi terbakar atau menyengat, ertitema atau kemerahan, kulit kering (ringan), peneglupasan kulit.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 13,
    "Kategori": "Antivirus",
    "NamaObat": "OSELTAMIVIR",
    "Kemasan": "75 MG",
    "BentukObat": "Kapsul",
    "Golongan": "Obat Keras",
    "GambarObat": img_13,
    "Deskripsi": "OSELTAMIVIR berguna Untuk mengatasi infeksi virus influenza tipe A (misalnya flu burung) atau B.",
    "IndikasiUmum": "Untuk mengatasi infeksi virus influenza tipe A (misalnya flu burung) atau B.",
    "Komposisi": "Oseltamivir 75 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nInfluenza A dan B Treatment: Dewasa, 2 kali per hari selama 5 hari",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitif terhadap oseltamivir",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada wanita hamil, penderita gangguan fungsi hati dan ginjal.",
    "EfekSamping": "Sakit perut, sakit kepala, diare, mual dan muntah, sulit tidur",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 14,
    "Kategori": "Antivirus",
    "NamaObat": "FAVIPIRAVIR",
    "Kemasan": "200 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_14,
    "Deskripsi": "Obat yang mengandung zat aktif Favipiravir. Avigan termasuk golongan antivirus dan digunakan sebagai anti virus influenza. Obat ini dimetabolisme di sel menjadi bentuk ribosyl triphosphate (favipiravir RTP). Favipiravir RTP menghambat secara selektif RNA poymerase yang berperan dalam replikasi virus influenza. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Mengatasi infeksi virus seperti virus influenza A, B, serta C.",
    "Komposisi": "Setiap tablet mengandung Favipiravir 200 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis dewasa : 1600 mg, 2 kali sehari untuk konsumsi di hari pertama. Diikuti 600 mg, 2 kali sehari selama 4 hari (di hari ke 2-5). Total durasi penggunaan adalah 5 hari.",
    "AturanPakai": "Dikonsumsi sesudah makan",
    "KontraIndikasi": "Tidak boleh digunakan pada wanita hamil atau wanita yang mungkin hamil. Hipersensitif terhadap zat tambahan apapun di dalam tablet Favipiravir.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pengobatan harus dihentikan pada pasien yang hamil. Favipiravir terdistribusi dalam Air Susu Ibu (ASI). Favipiravir terdistribusi dalam sperma laki-laki. Hati-hati penggunaannya pada pasien dengan level asam urat tinggi. Hati-hati pemberian pada pasien usia lanjut, diperlukan pemantauan secara berkala. Tidak boleh diberikan pada anak-anak.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Peningkatan kadar asam urat, diare.",
    "KategoriKehamilan": "Kategori X: Obat ini dikontraindikasikan untuk wanita hamil dan yang berkemungkinan untuk hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 15,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "GRISEOVULFIN",
    "Kemasan": "125 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_15,
    "Deskripsi": "GRISEOFULVIN TABLET adalah obat antijamur fungistatik yang bekerja dengan menghambat pertumbuhan jamur. Griseofulvin digunakan untuk pengobatan infeksi jamur pada kulit, kulit kepala, dan kuku bila pengobatan secara topikal mengalami kegagalan atau tidak cocok. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "INFORMASI OBAT INI HANYA UNTUK KALANGAN MEDIS. Pengobatan infeksi jamur pada kulit, kulit kepala, dan kuku bila pengobatan secara topikal gagal.",
    "Komposisi": "Griseofulvin 125 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dewasa: 500 mg per hari dosis tunggal atau dosis terbagi. Anak-anak: 10 mg per Kg BB dalam dosis tunggal atau dosis terbagi.",
    "AturanPakai": "Dikonsumsi bersama dengan makanan.",
    "KontraIndikasi": "Hipersensitif terhadap griseofulvin, lupus eritema tosus sistemik (SLE), porfiria, gangguan hati yang parah, kehamilan.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Gangguan hati ringan sampai sedang, anak-anak, laktasi, fotosensitivitas (hindari paparan langsung sinar matahari atau sinar ultaviolet)",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Ruam kulit, urtikaria, kering pada mulut, mual, muntah, sakit kepala, diare.",
    "KategoriKehamilan": "Kategori X: Obat ini dikontraindikasikan untuk wanita hamil dan yang berkemungkinan untuk hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 16,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "KETOKONAZOL",
    "Kemasan": "200 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_16,
    "Deskripsi": "KETOCONAZOLE 200 MG TABLET adalah obat anti fungal golongan imidazol yang digunakan untuk terapi infeksi jamur didaerah kulit atau tubuh. Obat mengganggu sintesis membran sel jamur dengan cara menghambat enzim sitokrom P450 14-alpha-demethylase (P45014DM). Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Infeksi jamur sistemik, kandidiasis mukokutan kronis yang tidak responsif terhadap nistatin & obat-obat lainnya",
    "Komposisi": "Ketoconazole 200 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Infeksi mikosis: Dewasa 1 tablet per hari selama 14 hari. Jika respon tidak ada, dapat ditingkatkan menjadi 400 mg. Kandidiasis vaginal: 2 tablet selama 5 hari.",
    "AturanPakai": "Diberikan segera setelah makan.",
    "KontraIndikasi": "Penyakit hati, fase penyembuhan hepatitis, hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Wanita hamil dan menyusui. Penderita dengan gangguan fungsi hati dan Insufisiensi adrenal",
    "EfekSamping": "Gangguan gastrointestinal; pruritus; peningkatan hasil tes fungsi hati. Jarang, reaksi alergi akut, hepatitis, ginekomestia",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 17,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "KETOKONAZOL",
    "Kemasan": "2%",
    "BentukObat": "Krim",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_17,
    "Deskripsi": "KETOKONAZOLE CREAM merupakan obat untuk mengatasi infeksi jamur pada kulit seperti Dermatofitosis (Tinea Korporis, Tinea Kruris, Tinea Manus, Tinea Pedis), P. Versikolor, dan Candidiasis. Obat ini digunakan dengan cara mengoleskan obat pada daerah kulit yang infeksi, 2 kali per hari.",
    "IndikasiUmum": "Untuk pengobatan topikal pada pengobatan infeksi dematofita pada kulit",
    "Komposisi": "Ketoconazole 2%",
    "Dosis": "Dewasa: oleskan 2 sampai 3 kali per hari pada tempat yang infeksi.",
    "AturanPakai": "Dioleskan pada daerah yang terinfeksi dan sekitarnya pada penderita kandidosis kutis, tinea korporis, tinea kruris, tinea manus, tinea pedis dan tinea (pitiriasis) versikolor. Pada penderita dermatitis seboroik : pengobatan sekali atau dua kali sehari. Pengobatan harus dilanjutkan untuk beberapa waktu, sedikitnya sampai beberapa hari setelah gejala-gejala hilang.",
    "KontraIndikasi": "Hipersensitifitas",
    "Perhatian": "Apabila terjadi iritasi maka pemakaian obat ini harus dihentikan. Keamanan dan efektifitas pada anak belum diketahui dengan pasti. Krim ini tidak boleh digunakan untuk mata. Hati-hati penggunaan pada wanita hamil dan menyusui. Untuk mencegah rebound effect pada pasien yang menggunakan kortikosteroid topikal jangka panjang, dianjurkan untuk melanjutkan penggunaan kortikosteroid topikal bersamaan dengan ketoconazole topikal dan secara bertahap dosis steroid diturunkan selama 2-3 minggu sebelum menghentikannya.",
    "EfekSamping": "Iritasi, rasa terbakar dan pruritus.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 18,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "MIKONAZOLE",
    "Kemasan": "2%",
    "BentukObat": "Krim",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_18,
    "Deskripsi": "MICONAZOLE CREAM merupakan obat antijamur golongan imidazol yang mengandung miconazole. Krim ini digunakan secara topikal atau pada membran mukosa untuk mengobati infeksi yang disebabkan fungi Trichophyton, Epidermophyton, Microsporum, Candida, dan Malessezia furfur. Miconazol bekerja menghambat biosintesis ergosterol pada jamur dan mengubah komposisi komponen lipid lain di dalam membran, sehingga mengakibatkan nekrosis sel jamur.",
    "IndikasiUmum": "Obat ini digunakan untuk terapi Tinea Pedis (kaki atlet), Tinea Kruris & Tinea Korporis yang disebabkan oleh Trikhofiton Rubrum, Trikhofiton Mentagrofits & Epidermofiton Flokosum, kandidiasis Kutaneus (Moniliasis) & Tinea Versikolor.",
    "Komposisi": "Miconazole nitrate 2%",
    "Dosis": "Oleskan 2-3 kali per hari selama 2 sampai 4 minggu.",
    "AturanPakai": "Dioleskan dengan keadaan bersih pada area kulit yang sakit atau terinfeksi.",
    "KontraIndikasi": "Hipersensitivitas dan bayi usia kurang dari 4 bulan.",
    "Perhatian": "Peringatan : Awas, Obat Keras. Hanya untuk bagian luar badan. Hindari kontak langsung dengan mata. Hentikan penggunaan jika terjadi iritasi. Hamil dan laktasi. Kategori Kehamilan : Kategori C: Mungkin berisiko. Obat digunakan dengan hati-hati apabila besarnya manfaat yang diperoleh melebihi besarnya risiko terhadap janin. Penelitian pada hewan uji menunjukkan risiko terhadap janin dan belum terdapat penelitian langsung terhadap wanita hamil.",
    "EfekSamping": "Sensasi rasa terbakar, maserasi, dermatitis alergik",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 19,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "NISTATIN",
    "Kemasan": "500.000 IU",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_19,
    "Deskripsi": "NYSTATIN merupakan antijamur yang digunakan untuk mengobati infeksi akibat jamur Candida spp, seperti pada mulut, kerongkongan, saluran pencernaan, dan vagina. Obat ini bekerja dengan cara mengganggu permeabilitas dinding sel jamur sehingga menyebabkan kematian jamur. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pengobatan infeksi jamur dalam rongga mulut",
    "Komposisi": "Nystatin 500000 IU",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. –\nDewasa: 500.000-1.000.000 unit, 3–4 kali sehari.\u000bAnak-anak: 100.000 unit, 4 kali sehari.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitivitas.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Tidak boleh digunakan untuk terapi mikosis sistemik. Hentikan terapi jika timbul iritasi atau sensitiasi.",
    "EfekSamping": "Diare, rasa tidak nyaman pada gastrointestinal, mul dan muntah (dosis besar)",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 20,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "NISTATIN",
    "Kemasan": "100.000 IU",
    "BentukObat": "Tablet Vaginal",
    "Golongan": "Obat Keras",
    "GambarObat": img_20,
    "Deskripsi": "NYSTATIN OVULA merupakan obat yang mengandung metronidazole dan nystatin. Metronidazole adalah obat yang digunakan untuk menangani gejala injeksi bakteri seperti keputihan, digunakan untuk terapi post-operasi serta penyakit seperti amoebiasis, tricomoniasis, vaginosis bakterial. Metronidazole bekerja dengan cara menghentikan pertumbuhan dari bakteri dan protozoa yang sedang menginfeksi tubuh kita sehingga keluhan-keluhan dapat berkurang. Nystatin sebagai anti-jamur, bekerja dengan cara menghambat laju pertumbuhan dan merusak sel jamur Candida. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pengobatan infeksi jamur, keputihan",
    "Komposisi": "Nystatin 100.000 IU",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. 100.000-200.000 IU setiap hari, hingga 14 hari atau lebih.",
    "AturanPakai": "Basahi ovula untuk mempercepat penghancuran obat kemudian dimasukkan ke dalam vagina. Diamkan bebeapa menit (jangan langsung berdiri). Lakukan pada malam hari.",
    "KontraIndikasi": "Hipersensitivitas.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. pasien dengan imunosupompresi. anak, ibu hamil dan menyusui.",
    "EfekSamping": "Diare, rasa tidak nyaman pada gastrointestinal, mul dan muntah (dosis besar)",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 21,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "NYMIKO (NISTATIN SUSPENSI)",
    "Kemasan": "100.000 IU",
    "BentukObat": "Suspensi",
    "Golongan": "Obat Keras",
    "GambarObat": img_21,
    "Deskripsi": "NYMIKO DROPS adalah obat anti jamur yang mengandung nystatin untuk mengobati kandidiasis pada rongga mulut. Nystatin dapat bersifat fungistatik dan fungisidal terhadap beberapa jenis jamur seperti Candida albicans, C. parapsilosis, C. tropicalis, C. guilermondi, C. pseudotropicalis, C. krusei. Nystatin bekerja dengan cara mengikat sterol dalam membran sel jamur yang peka terhadap obat ini sehingga terjadi perubahan permeabilitas membran dan terjadi kebocoran komponen intrasel yang menyebabkan kematian jamur. Dalam menggunakan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "INFORMASI OBAT INI HANYA UNTUK KALANGAN MEDIS. Nymiko digunakan untuk mengatasi infeksi jamur kandida pada mulut, saluran usus, dan disekitar vagina.",
    "Komposisi": "Per-mL : Nystatin 100,000 IU",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 4 kali sehari 5 mL\nBayi dan anak : 4 kali sehari 1 mL. \ninfeksi pada rongga mulut karena candida albicans : kumur-kumur suspensi sebelum di telan",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hipersensitivitas.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER",
    "EfekSamping": "Gangguan gastrointestinal, diare, mual, dan muntah",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 22,
    "Kategori": "Antijamur & Antiparasit",
    "NamaObat": "SCABIMITE (PERMETRIN 5%)",
    "Kemasan": "5%",
    "BentukObat": "Krim",
    "Golongan": "Obat Keras",
    "GambarObat": img_22,
    "Deskripsi": "SCABIMITE CREAM adalah obat topikal yang mengandung Permethrin yang digunakan untuk mengobati infeksi kulit skabies yang disebabkan oleh Tungau (mite) Sarcoptes scabiei. Permethrin merupakan obat golongan Pyrethrin yang bekerja dengan cara membunuh tungau beserta telurnya. SCABIMITE CREAM digunakan dengan cara mengoleskan cream keseluruh tubuh termasuk wajah, leher, kulit kepala dan telinga. Setelah 8-24 jam, obat dibilas dengan cara dicuci menggunakan sabun. Pengobatan dapat diulangi setelah 1 minggu. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Skabies(kudis)",
    "Komposisi": "Permethrin 5%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Sekali pemakaian pada malam hari. Oleskan secara merata pada seluruh permukaan kulit dari kepala sampai ke jari-jari kaki, terutama daerah belakang telinga, lipatan bokong dan sela-sela jari. Biarkan selama 8-12 jam, lalu cuci sampai bersih pada keesokan harinya. Jika selama pemakaian tidak sengaja tercuci, cream harus dioleskan kembali.",
    "AturanPakai": "Oleskan pada bagian yang membutuhkan",
    "KontraIndikasi": "Hipersensitif terhadap pyrethroid atau pyrethrin sintetik",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hindari kontak dengan mata",
    "EfekSamping": "Rasa seperti terbakar dan tersengat yang ringan yang bersifat sementara, gatal, eritema, ruam kulit",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 23,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "PARASETAMOL",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_23,
    "Deskripsi": "PARACETAMOL TABLET merupakan obat yang dapat digunakan untuk meringankan rasa sakit pada sakit kepala, sakit gigi, dan menurunkan demam. Paracetamol bekerja pada pusat pengatur suhu di hipotalamus untuk menurunkan suhu tubuh (antipiretik) serta menghambat sintesis prostaglandin sehingga dapat mengurangi nyeri ringan sampai sedang (analgesik).",
    "IndikasiUmum": "Obat ini digunakan untuk meredakan nyeri ringan hingga sedang seperti sakit kepala, sakit gigi, nyeri otot, serta menurunkan demam.",
    "Komposisi": "Setiap tablet mengandung Paracetamol 500 mg",
    "Dosis": "Dewasa: 1-2 kaplet, 3-4 kali per hari. Penggunaan maximum 8 kaplet per hari. \nAnak 7-12 tahun : 0.5 - 1 kaplet, 3-4 kali per hari. Penggunaan maximum 4 kaplet per hari.",
    "AturanPakai": "Obat dapat diminum sebelum atau sesudah makan",
    "KontraIndikasi": "Parasetamol jangan diberikan kepada penderita hipersensitif/alergi terhadap Paracetamol. Penderita gangguan fungsi hati berat.",
    "Perhatian": "Hati-hati penggunaan pada pasien dengan gagal ginjal, gangguan fungsi hati, dan alergi atau mengalami hipersensitivitas terhadap paracetamol.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: - Penggunaan untuk jangka waktu lama dan dosis besar dapat menyebabkan kerusakan fungsi hati. - Reaksi hipersensitifitas/ alergi.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 24,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "PARASETAMOL",
    "Kemasan": "120 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Bebas",
    "GambarObat": img_24,
    "Deskripsi": "PARACETAMOL SIRUP merupakan obat generik yang digunakan untuk meredakan sakit kepala, nyeri dan demam.",
    "IndikasiUmum": "Untuk meringankan demam, nyeri, sakit kepala dan sakit gigi.",
    "Komposisi": "Tiap 5 ml mengandung : Paracetamol 120 mg",
    "Dosis": "Anak (1-2 tahun) : 3-4 kali sehari 1 sendok takar (@ 5 mL). \nAnak (2-6 tahun) : 3-4 kali sehari 1-2 sendok takar (@ 5ml - 10 ml). \nAnak (6-9 tahun) : 3-4 kali sehari 2-3 sendok takar (@ 10 ml -15 ml). \nAnak (9-12 tahun) : 3-4 kali sehari 3-4 sendok takar (@ 15 ml- 20 ml).",
    "AturanPakai": "Dikonsumsi sebelum atau sesudah makan.",
    "KontraIndikasi": "Hipersensitif terhadap parasetamol. Pasien dengan disfungsi hati dan ginjal",
    "Perhatian": "Hati-hati penggunaan obat ini pada penderita penyakit ginjal, gangguan fungsi hati, ibu hamil, dan masa laktasi (menyusui). Selama minum obat ini, dianjurkan untuk mengurangi konsumsi makanan atau minuman yang mengandung kafein, seperti: teh, kopi, dan cola untuk menghidari timbulnya rasa gelisah, iritabilitas, sukar tidur, dan jantung berdebar akibat kadar kafein dalam darah yang terlalu tinggi. Hentikan penggunaan obat ini jika rasa sakit tidak mereda selama 5 hari, dan segera konsultasikan hal tersebut kepada dokter Anda.",
    "EfekSamping": "Hematologikal, reaksi kulit, reaksi alergi",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 25,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "PARASETAMOL",
    "Kemasan": "100 MG/1 ML",
    "BentukObat": "Drop",
    "Golongan": "Obat Bebas",
    "GambarObat": img_25,
    "Deskripsi": "PARACETAMOL merupakan obat generik yang digunakan untuk meredakan sakit kepala, nyeri dan demam.",
    "IndikasiUmum": "analgesik, antipiretik",
    "Komposisi": "Per 1 ml : Paracetamol 100 mg",
    "Dosis": "anak 1-2 tahun : 3-4 x sehari 0.6-1.2 mL. \nanak <1 thn : 3-4 x sehari 0.6 mL",
    "AturanPakai": "dapat di berikan bersamaan makanan",
    "KontraIndikasi": "gangguan fungsi hati yang berat.",
    "Perhatian": "Hati-hati penggunaan obat ini pada penderita ginjal. Bila setelah 2 hari demam tidak menurun atau setelah 5 hari nyeri tidak menghilang, segera hubungi unit pelayanan kesehatan. Penggunaan obat ini pada penderita yang mengkonsumsi alkohol, dapat meningkatkan risiko kerusakan fungsi hati",
    "EfekSamping": "jangka lama dan dosis besar dapat menyebabkan kerusakan hati, hipersensitifitas.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 26,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "PARACETAMOL",
    "Kemasan": "160 MG",
    "BentukObat": "Suppositoria",
    "Golongan": "Obat Keras",
    "GambarObat": img_26,
    "Deskripsi": "PROPYRETIC SUPPOSITORIA adalah obat yang di gunakan sebagai penurun demam dan pereda nyeri seperti sakit kepala, sakit gigi dan nyeri ringan. PROPYRETIC SUPPOSITORIA mengandung paracetamol yang memiliki fungsi sebagai antipiretik dan analgesik. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pereda rasa sakit & menurunkan suhu tubuh pada waktu demam",
    "Komposisi": "Paracetamol 160 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nRektal: anak-anak<12 tahun: 10-15 mg/kg berat badan, \n6-12 tahun: 240 mg, \n2-6 tahun: 160 mg, \nbayi<2 tahun: 80 mg. Untuk digunakan 4 kali sehari.",
    "AturanPakai": "Dimasukkan melalui dubur",
    "KontraIndikasi": "Penderita yang hipersensitif dengan Paracetamol, penderita yang memiliki gangguan fungsi hati",
    "Perhatian": "HARUS DENGAN RESEP DOKTER.",
    "EfekSamping": "Iritasi",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": "Suppositoria"
  },
  {
    "No": 27,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "ASAM MEFENAMAT",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_27,
    "Deskripsi": "ASAM MEFENAMAT atau MEFENAMIC ACID merupakan obat yang termasuk dalam golongan anti infalamasi non steroid sebagai anti nyeri pada tingkat ringan hingga sedang. Obat ini dapat digunakan untuk meredakan sakit kepala, sakit gigi, nyeri haid, nyeri akibat trauma, nyeri pada otot dan nyeri sesudah operasi. Asam mefenamat bekerja dengan cara menghambat sintesa prostaglandin dalam jaringan tubuh dengan menghambat enzim siklooksigenase. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Obat ini digunakan untuk meredakan nyeri ringan hingga sedang seperti sakit kepala, sakit gigi, nyeri haid, nyeri akibat trauma, nyeri pada otot dan nyeri sesudah operasi",
    "Komposisi": "Asam Mefenamat 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dosis dewasa dan anak usia lebih dari 14 tahun : Dosis awal 500 mg, kemudian dilanjutkan 250 mg tiap 6 jam, sesuai dengan kebutuhan.",
    "AturanPakai": "Diberikan sesudah makan makan",
    "KontraIndikasi": "Pasien yang hipersensitif terhadap asam mefenamat. Penderita yang dengan aspirin mengalami bronkospasme, alergi rhinitis dan urtikaria. Penderita dengan tukak lambung dan usus. Penderita dengan gangguan ginjal yang berat.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pasien dengan gangguan atau faktor resiko kardiovaskular, gangguan hati dan ginjal, ibu hamil dan menyusui",
    "EfekSamping": "Sistem pencernaan : mual, muntah, diare dan rasa sakit pada abdominal. Sistem hematopoetik : leukopenia eosinophilia, trombocytopenia dan agranilocytopenia. Sistem Syaraf : rasa mengantuk, pusing, penglihatan kabur dan insomnia.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 28,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "IBUPROFEN",
    "Kemasan": "200 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_28,
    "Deskripsi": "IBUPROFEN 200 MG merupakan obat golongan anti inflamasi non steroid yang mempunyai efek anti inflamasi, analgesik dan antipiretik. Obat ini bekerja menghambat sintesis prostaglandin sehingga dapat mengurangi nyeri ringan sampai sedang, antara lain nyeri pada sakit kepala, nyeri haid, nyeri pada penyakit gigi atau pencabutan gigi, dan nyeri setelah operasi. Selain itu, obat ini digunakan sebagai analgesik dan anti inflamasi dengan meringankan gejala-gejala penyakit rematik tulang, sendi dan non sendi, meringankan gejala-gejala akibat trauma otot dan tulang/sendi.",
    "IndikasiUmum": "Obat ini digunakan sebagai analgesik yaitu untuk meringankan nyeri ringan sampai sedang antara lain nyeri pada sakit kepala, nyeri haid, nyeri pada penyakit gigi atau pencabutan gigi, dan nyeri setelah operasi.",
    "Komposisi": "Ibuprofen 200 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER.\nDewasa : 200-800 mg, 3-4 kali sehari. Dosis maksimal per hari adalah 3,2 gram.\nDosis anak usia 6 bulan ke atas: 4-10 mg/kgBB setiap 6-8 jam.",
    "AturanPakai": "Dikonsumsi sesudah makan",
    "KontraIndikasi": "Hipersensitif (termasuk asma) terhadap ibuprofen dan obat AINS lain. Riwayat perdarahan gastrointestinal, perforasi, atau ulserasi terkait dengan terapi NSAID. Ulserasi gastrointestinal, perforasi, atau perdarahan. Kehamilan trimester ketiga",
    "Perhatian": "Peringatan : Awas, Obat Keras Bacalah Aturan Pakainya. Hati- hati penderita gangguan fungsi ginjal, gagal jantung, hipertensi, hiperlipidemia dan diabetes melitus. Hati-hati penggunaan pada wanita hamil trimester 1 dan 2, dan ibu menyusui.",
    "EfekSamping": "Mual, muntah, diare, konstipasi/sembelit, nyeri kambung, sakit kepala, pusing.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.\n\nKategori D pada ibu hamil trimester ke-3: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 29,
    "Kategori": "Obat Demam, Nyeri, & Pusing",
    "NamaObat": "NATRIUM DIKLOFENAK",
    "Kemasan": "50 mg",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_29,
    "Deskripsi": "NATRIUM DIKLOFENAK 50 MG TABLET merupakan obat golongan nonsteroid anti inflamasi (NSAID) yang digunakan sebagai pereda nyeri, mengurangi gangguan inflamasi, dismenore, nyeri ringan sampai sedang pasca operasi khususnya ketika pasien mengalami peradangan. Natrium Diklofenak bekerja dengan menghambat kerja enzim siklooksigenase yang berfungsi untuk membantu pembentukan prostaglandin saat terjadinya luka dan menyebabkan rasa sakit dan peradangan. Dengan menghalangi kerja enzim siklooksigenase, prostaglandin lebih sedikit di produksi sehingga rasa sakit dan peradangan akan mereda. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Meredakan nyeri dan mengurangi inflamasi pada pasien Rematoid Atritis akut dan kronis, nyeri pada tulang, spondilitis ankilosa",
    "Komposisi": "Natrium Diklofenak 50 mg",
    "Dosis": "Dewasa: 50 MG, 2-3 kali sehari Anak-anak >14 tahun: 25 MG, 3 kali sehari atau 50 MG, 2 kali sehari",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Penderita yang hipersensitif terhadap diklofenac atau yang menderita asma, urtikaria atau pada pemberian aspirin. Penderita tukak lambung.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Memiliki riwayat penyakit atau faktor resiko gangguan kardiovaskular, hipertensi, retensi cairan, gagal jantung. Memiliki riwayat penyakit tukak lambung/usus, penyakit Crohn, gangguan fungsi hati, gangguan fungsi ginjal, asma, lansia, anak-anak, ibu hamil dan menyusui. Hipersensitif terhadap diklofenak.",
    "EfekSamping": "Efek samping yang umum terjadi seperti nyeri/kram perut, sakit kepala, retensi cairan, diare, nausea, konstipasi, flatulen, kelainan pada hasil uji hati, indigesti, tukak lambung, pusing, ruam, pruritus dan tinitus. Peninggian enzim-enzim aminotransferas",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 30,
    "Kategori": "Obat Alergi/Pilek",
    "NamaObat": "KLORFENIRAMIN MALEAT (CTM)",
    "Kemasan": "4 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_30,
    "Deskripsi": "Klorfeniramin Maleat 4 MG TABLET merupakan obat anti alergi yang mengandung zat aktif Chlorpheniramine maleat. CTM bekerja secara antagonis terhadap efek histamin pada reseptor H1, dimana dapat menyebabkan efek samping berupa mengantuk. Obat ini digunakan untuk mengatasi gejala alergi seperti rhinitis alergi, urtikaria, bersin-bersin, mata berair, gatal pada mata, hidung, tenggorokan atau kulit.",
    "IndikasiUmum": "Obat ini digunakan untuk mengatasi gejala alergi, seperti gatal-gatal, urtikaria, dermatitis.",
    "Komposisi": "Chlorpheniramine maleat 4 mg",
    "Dosis": "Dewasa dan anak usia >12 tahun: 4 mg, tiap 4–6 jam. Dosis maksimal 24 mg per hari.\nAnak usia 6–12 tahun: 2 mg, tiap 4–6 jam. Dosis maksimal 12 mg per hari.\nAnak usia 2–5 tahun: 1 mg, tiap 4–6 jam. Dosis maksimal 6 mg per hari.\nAnak usia 1–2 tahun: 1 mg, 2 kali sehari. Dosis maksimal 4 mg per hari.",
    "AturanPakai": "Diberikan sesudah makanan",
    "KontraIndikasi": "Neonatus (bayi baru lahir), bayi prematur, penderita serangan asma akut",
    "Perhatian": "Peringatan : Awas, Obat Keras Bacalah Aturan Pakainya. Selama minum obat ini tidak boleh mengendarai kendaraan bermotor atau menjalankan mesin. Jangan memberikan obat ini kepada anak usia di bawah 2 tahun tanpa berkonsultasi terlebih dahulu dengan dokter",
    "EfekSamping": "Mengantuk, pusing, sembelit, gangguan kecemasan, mual, penglihatan kabur, gelisah, penurunan koordinasi, mulut kering, sulit buang air kecil, iritabilitas, masalah konsentrasi.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 31,
    "Kategori": "Obat Alergi/Pilek",
    "NamaObat": "CETIRIZIN HCL",
    "Kemasan": "5 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Keras",
    "GambarObat": img_31,
    "Deskripsi": "CETIRIZINE adalah obat antihistamin yang berfungsi untuk meredakan gejala alergi seperti gatal-gatal, mata berair, pilek dan mata atau hidung gatal. Obat ini bekerja dengan menghalangi zat alami tertentu yang di produksi tubuh selama reaksi alergi. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pengobatan untuk gejala alergi",
    "Komposisi": "Per 5 mL : Cetirizine HCl 5 mg.",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa dan anak > 12 tahun : 2 sendok takar (@10 ml), 1 kali per hari. \nAnak 6-12 tahun : 1 sendok takar (@5 ml), 2 kali per hari.\nAnak 2-6 tahun : 1 sendok takar (@5 ml), 1 kali per hari.",
    "AturanPakai": "Dikonsumsi sesudah makan",
    "KontraIndikasi": "Hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Penderita epilepsi, gagal ginjal dan gangguan fungsi hati. Wanita hamil dan menyusui.",
    "EfekSamping": "Sakit kepala, pusing, mengantuk, gelisah, mulut kering dan gangguan saluran cerna.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 32,
    "Kategori": "Obat Batuk",
    "NamaObat": "ACETYL CYSTEINE",
    "Kemasan": "200 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_32,
    "Deskripsi": "ACETYLCYSTEINE 200 MG KAPSUL adalah obat generik yang digunakan sebagai mukolitik (pengencer dahak) dan antidot pada pasien yang overdosis Paracetamol. Fungsi mukolitik dari Acetylcysteine yaitu bekerja dengan cara mengurangi ikatan disulfida pada matriks mukus (dahak) yang membuat mukus tersebut encer sehingga mukus lebih mudah dikeluarkan serta mengurangi menempelnya mukus pada dinding tenggorokan. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Mengencerkan dahak atau lendir di saluran penafasan akibat penyakit paru-paru tertentu (misalnya: emfisema, bronkitis, fibrosis kistik, pneumnia).",
    "Komposisi": "Acetylcysteine 200 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "AturanPakai": "Dikonsumsi setelah makan",
    "KontraIndikasi": "Hipersensitivitas.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada: Penderita denga riwayat penyakit asma, bronkospasme, tukak lambung, varises esofagus. Anak-anak, wanita hamil dan menyusui",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: 1. Reaksi Reaksi hipersensitivitas (misalnya urtikaria, ruam kulit, hipotensi, mengi, dispnea). 2. Gangguan saluran pencernaan: mual, muntah. 3. Berpotensi fatal: Reaksi hipersensitivitas serius (misalnya bronkospasme, angioedema).",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 33,
    "Kategori": "Obat Batuk",
    "NamaObat": "AMBROXOL",
    "Kemasan": "15 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Keras",
    "GambarObat": img_33,
    "Deskripsi": "AMBROXOL SIRUP 15 MG/5 ML adalah obat batuk mukolitik yaitu obat yang digunakan sebagai pengencer dahak. Ambroxol bekerja dengan cara memecah serat asam mukopolisakarida yang membuat dahak lebih encer dan mengurangi adhesi lendir pada dinding tenggorokan sehingga mempermudah pengeluaran lendir pada saat batuk. Obat ini digunakan untuk penyakit pada saluran pernafasan dimana terjadi banyak lendir atau dahak, seperti : emfisema, radang paru kronis, bronkiektasis, eksaserbasi bronkitis kronis dan akut, bronkitis asmatik, asma bronkial yang disertai kesukaran pengeluaran dahak, serta penyakit radang rinofaringeal. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "pengobatan saliran pernapasanakut dan kronis dengan sekresi bronkial yang abnormalterutama serangan akut bronkhitis kronis, asma bronkial, bronkhitis asmatik, terapi pra dan pasca operasi perawatan intensifuntuk menghindari komplikasi paru",
    "Komposisi": "Per 5 mL : Ambroxol HCl 15 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis ambroxol dewasa: 10 ml, 2-3 kali per hari.\nDosis ambroxol anak-anak :\nUsia 2-5 tahun: 2.5 ml, 2-3 kali per hari.\nUsia 6-12 tahun: 5 ml, 2-3 kali per hari.",
    "AturanPakai": "Obat diminum sesudah makan",
    "KontraIndikasi": "hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Penggunaan jangka lama. Insufisiensi ginjal, Hamil, Laktasi",
    "EfekSamping": "Gangguan GI ringan, rekasi alergi",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 34,
    "Kategori": "Obat Batuk",
    "NamaObat": "GUAFENESIN",
    "Kemasan": "100 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_34,
    "Deskripsi": "Guaifenesin adalah obat untuk mengatasi batuk berdahak atau meredakan penumpukan dahak di saluran pernapasan akibat flu atau bronkitis akut. Obat ini tidak digunakan untuk meredakan batuk yang disebabkan oleh penyakit paru obstruktif kronis.\nGuaifenesin bekerja dengan cara mengencerkan dahak, sehingga dahak bisa lebih mudah dikeluarkan dari saluran pernapasan. Obat ini sering dikombinasikan dengan obat lain di dalam produk obat flu dan batuk",
    "IndikasiUmum": "Untuk meredakan batuk",
    "Komposisi": "Guafenesin 100 mg",
    "Dosis": "Dewasa: 200–400 mg, tiap 4 jam, atau untuk tablet pelepasan lambat 600–1.200 mg, tiap 12 jam. Dosis maksimal adalah 2.400 mg per hari.\nAnak usia 6–12 tahun: 100–200 mg, 4 kali sehari. Dosis maksimal 400 mg per hari.",
    "AturanPakai": "Dikonsumsi setelah makan",
    "KontraIndikasi": "hipersensitivitas",
    "Perhatian": "Konsultasikan dengan dokter terlebih dahulu sebelum menggunakan guafenesin jika Anda menderita asma, bronkitis kronis, emfisema, batuk berdahak yang sudah berlangsung cukup lama, penyakit hati, batuk darah, atau fenilketonuria (PKU).",
    "EfekSamping": "Beberapa efek samping yang bisa terjadi setelah mengonsumsi guaifenesin, yaitu Pusing, Sakit Kepala, Mual, Muntah, Sakit perut, Diare",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 35,
    "Kategori": "Obat Batuk",
    "NamaObat": "LASERIN",
    "Kemasan": "",
    "BentukObat": "Sirup",
    "Golongan": "Jamu",
    "GambarObat": img_35,
    "Deskripsi": "Laserin merupakan obat herbal yang digunakan untuk meredakan batuk secara tradisional. Mengandung berbagai bahan herbal seperti jahe, cengkeh, daun sirih, dan ekstrak patikan yang pada dasarnya memiliki efek mempermudah pengeluaran dan mengencerkan lendir pada saluran napas serta memberikan sensasi manis dan hangat pada tenggorokan.",
    "IndikasiUmum": "Membantu meredakan batuk, masuk angin, muntah, sakit perut dan membantu melegakan tenggorokan",
    "Komposisi": "Herba euphorbiahirta 0,15 g, jahe 6 g, cengkeh 0,6 g, daun sirih 1,8 g, daun saga 0,3 g, buah kardamon 0,15 g, mentha arvensis 0,15 g, daun hibiscus 0,15 g, minyak permen 0,015 ml, sari akar manis 0,015 g",
    "Dosis": "Dewasa: 3 x sehari 1-2 sendok makan; Anak: 3 x sehari 1-2 sendok teh; Bayi: 2 x sehari 1/2 sendok the",
    "AturanPakai": "Sesudah Makan",
    "KontraIndikasi": "",
    "Perhatian": "",
    "EfekSamping": "",
    "KategoriKehamilan": "",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 36,
    "Kategori": "Obat Batuk",
    "NamaObat": "OBH",
    "Kemasan": "",
    "BentukObat": "Sirup",
    "Golongan": "Obat Bebas",
    "GambarObat": img_36,
    "Deskripsi": "OBH merupakan Obat Batuk Hitam dengan kandungan succus liquiritiae, ammonium chloride, dan sasa. Obat ini digunakan untuk mengobati batuk berdahak dengan cara memudahkan pengeluaran dahak.",
    "IndikasiUmum": "Batuk berdahak",
    "Komposisi": "Succus liquiritiae 166 mg, ammon Cl 100 mg, SASA 100 mg",
    "Dosis": "Dewasa : 3-4 x sehari 1 sendok makan. Anak : 3-4 x sehari 1 sendok teh.",
    "AturanPakai": "Berikan setelah makan",
    "KontraIndikasi": "Penderita yang hipersensitif terhadap salah satu komponen obat.",
    "Perhatian": "Jangan digunakan pada pasien yang hipersensitif terhadap komposisi obat",
    "EfekSamping": "Mengantuk",
    "KategoriKehamilan": "",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 37,
    "Kategori": "Obat Batuk",
    "NamaObat": "WICOLD",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_37,
    "Deskripsi": "Wicold adalah obat yang mengandung zat aktif Paracetamol, Glyceryl guaiacolate, Chlorpheniramine maleate, Phenylephrine HCl. Wicold digunakan untuk mengatasi gejala flu, seperti demam, pusing, hidung tersumbat, bersin-bersin yang di sertai dengan batuk berdahak. Paracetamol berfungsi untuk menurunkan demam dan meredakan nyeri, Glyceryl guaiacolate berfungsi untuk mengencerkan dahak, Chlorpheniramine maleate berfungsi untuk meredakan gejala alergi, Phenylephrine HCl berfungsi untuk melegakkan hidung tersumbat.",
    "IndikasiUmum": "Wicold digunakan untuk mengatasi gejala flu yang di sertai dengan batuk berdahak.",
    "Komposisi": "Paracetamol 500 mg, Glyceryl guaiacolate 50 mg, Chlorpheniramine maleate 2 mg, Phenylephrine HCl 5 mg",
    "Dosis": "Dewasa: 3-4 kaplet per hari.",
    "AturanPakai": "Dikonsumsi setelah makan",
    "KontraIndikasi": "Penderita yang hipersensitif terhadap komponen obat.\nPenderita gangguan fungsi hati yang berat.",
    "Perhatian": "",
    "EfekSamping": "Mengantuk\nKerusakan hati jika digunakan dalam dosis besar dan dalam jangka waktu yang lama\nMulut kering",
    "KategoriKehamilan": "",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 38,
    "Kategori": "Obat Batuk",
    "NamaObat": "WICOLD",
    "Kemasan": "",
    "BentukObat": "Sirup",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_38,
    "Deskripsi": "Wicold adalah obat yang mengandung zat aktif Paracetamol, Glyceryl guaiacolate, Chlorpheniramine maleate, Phenylephrine HCl. Wicold digunakan untuk mengatasi gejala flu, seperti demam, pusing, hidung tersumbat, bersin-bersin yang di sertai dengan batuk berdahak. Paracetamol berfungsi untuk menurunkan demam dan meredakan nyeri, Glyceryl guaiacolate berfungsi untuk mengencerkan dahak, Chlorpheniramine maleate berfungsi untuk meredakan gejala alergi, Phenylephrine HCl berfungsi untuk melegakkan hidung tersumbat.",
    "IndikasiUmum": "Wicold digunakan untuk mengatasi gejala flu yang di sertai dengan batuk berdahak.",
    "Komposisi": "Paracetamol 125 mg, Glyceryl guaiacolate 30 mg, Chlorpheniramine maleate 0.5 mg, Phenylephrine HCl 2 mg Per 5 ml",
    "Dosis": "Anak usia 2–6 tahun: 1 sendok teh (5 ml), diminum 3-4 kali sehari.\nAnak usia 6–12 tahun: 2 sendok teh (10 ml), diminum 3-4 kali sehari.\nDewasa: 1 sendok makan (15 ml), diminum 3-4 kali sehari.",
    "AturanPakai": "Dikonsumsi setelah makan",
    "KontraIndikasi": "Penderita yang hipersensitif terhadap komponen obat.\nPenderita gangguan fungsi hati yang berat.",
    "Perhatian": "",
    "EfekSamping": "Mengantuk\nKerusakan hati jika digunakan dalam dosis besar dan dalam jangka waktu yang lama\nMulut kering",
    "KategoriKehamilan": "",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 39,
    "Kategori": "Obat Radang",
    "NamaObat": "PREDNISON",
    "Kemasan": "5 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_39,
    "Deskripsi": "PREDNISON 5 MG TABLET adalah obat generik kortikosteroid sintetis. Obat ini digunakan terapi insufisiensi adrenokortikal, untuk memperoleh efek antiinflamasi atau imunosupresan. Dalam penggunaan obat ini HARUS SESUAI PETUNJUK DOKTER.",
    "IndikasiUmum": "Artritis reumatoid, asma bronkhial, lupus eritematosus sistemik, demam reumatik yang berhubungan dengan karditis",
    "Komposisi": "Prednisone 5 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa: 1 tablet, 2-4 kali per hari. \nAnak: 1-2 mg/kgBB per hari dalam 3-4 dosis terbagi",
    "AturanPakai": "Diberikan setelah makan",
    "KontraIndikasi": "Penderita penyakit tuberculosis aktif, infeksi akut, infeksi jamur, herpes simpleks mata, ulkus peptikum, hipertensi mengalami osteoporosis mengalami psikosis maupun psikoneurosis berat, serta sedang menerima vaksin hidup.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Penggunaan terus menerus & dalam jangka yang panjang pada anak-anak selama masa pertumbuhan. Penyakit jantung kongestif, gangguan fungsi ginjal. Hindari penghentian terapi mendadak. Hamil, laktasi",
    "EfekSamping": "Mual, anoreksia (kehilangan nafsu makan), nyeri otot,gelisah. Edema, hipernatremia, hipokalemia, iritasi lambung, hipernatremia, hiperkalemia. Gangguan tidur (pada awal terapi)",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.\n\nKategori D pada ibu hamil trimester ke-1: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 40,
    "Kategori": "Obat Radang",
    "NamaObat": "HIDROKORTISON",
    "Kemasan": "2.50%",
    "BentukObat": "Krim",
    "Golongan": "Obat Keras",
    "GambarObat": img_40,
    "Deskripsi": "HYDROCORTISON CREAM adalah obat adrenokortikal steroid yang memiliki sifat anti-inflamasi, anti alergi dan anti pruritus pada jaringan kulit. Hydrocortison di gunakan untuk mengobati eksim, inflamasi, kemerahan,serta gatal-gatal pada kulit, beberapa jenis infeksi kulit yang dapat diobati contohnya dermatitis alergi, dermatitis kontak, dermatitis atopi, pruritus anogenital, neurodermatitis. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Dermatitis atopik dan kontak.",
    "Komposisi": "Hydrocortisone acetate 2.5 %",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Oleskan pada kulit yang bermasalah 2-3 kali per hari.",
    "AturanPakai": "oleskan tipis pada bagian yang membutuhkan",
    "KontraIndikasi": "Hipersensitif, herpes simplex, vaksinia, varisela",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Infeksi kulit. Hindari penggunaan jangka panjang. Hindari penggunaan pada mata, membran mukosa, kulit yang sensitif atau luka terbuka",
    "EfekSamping": "Rasa terbakar, gatal, kulit kering, atropi, infeksi sekunder.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 41,
    "Kategori": "Obat Radang",
    "NamaObat": "BETAMETASON",
    "Kemasan": "0.10%",
    "BentukObat": "Krim",
    "Golongan": "Obat Keras",
    "GambarObat": img_41,
    "Deskripsi": "BETAMETHASONE 0.1% CREAM adalah obat kortikosteroid oles yang dapat digunakan untuk mengatasi reaksi alergi atau mengurangi peradangan kulit akibat sejumlah kondisi, seperti eksim serta dermatitis. Betamethasone bekerja dengan cara mencegah dan mengendalikan peradangan (inflamasi) dengan mengendalikan laju sintesis protein, menekan migrasi leukosit polimorfonuklear dan fibroblast, dan membalikkan permeabilitas kapiler dan stabilisasi lisosom. Obat ini digunakan untuk penyakit kulit seperti dermatitis dan psoriasis, sebagai krim topikal untuk meringankan iritasi kulit, seperti gatal-gatal dan mencreamupas dari eksim, penyakit Bullous dermatitis herpetiformis, eksfoliatif eritroderma, mikosis fungoides, pemfigus, eritema multiforme (sindrom Stevens-Johnson). Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Ekzema, termasuk ekzema atopik, infantil, stasis & diskoid & prurigo",
    "Komposisi": "Betamethasone 0.1%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dioleskan 3-4 kali sehari sampai fase akut berakhir, lalu oleskan 1 kali per hari.",
    "AturanPakai": "Oles tipis-tipis pada kulit yang bermasalah.",
    "KontraIndikasi": "Hipersensitif, TB kulit, infeksi jamur dan virus pada kulit.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Tuberkulosis kulit, tidak boleh kontak daerah mata, hamil, pemakaian jangka panjang.",
    "EfekSamping": "Perubahan atropik lokal pada kulit",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 42,
    "Kategori": "Obat Sesak Napas",
    "NamaObat": "SALBUTAMOL",
    "Kemasan": "4 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_42,
    "Deskripsi": "SALBUTAMOL 4 MG TABLET adalah obat golongan bronkodilator. Salbutamol merupakan obat golongan beta adrenergik agonis yang secara langsung dapat membuat otot-otot polos pada bronkus menjadi lebih rileks. Selain itu, salbutamol juga dapat menurunkan kontraktilitas uterus karena efek tokolitik yang dimilikinya. SALBUTAMOL TABLET digunakan untuk mengobati bronkospasme (misalnya penyakit asma karena alergi tertentu, asma bronkial, bronkitis asmatis, emfisema pulmonum), dan penyakit paru obstruktif kronik (PPOK). Dalam penggunaan obat ini HARUS SESUAI PETUNJUK DOKTER.",
    "IndikasiUmum": "Bronkospasme pada semua jenis asma bronkial, bronkritis kronik, dan emfisema",
    "Komposisi": "Salbutamol 4 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 3-4 kali sehari 1-2 tablet. \nAnak-anak berusia 6-12 tahun : 3 kali sehari 1 tablet. \nAnak berusia 2-6 tahun : 3 kali sehari 1/2 tablet.",
    "AturanPakai": "Sebelum makan",
    "KontraIndikasi": "Hipersensitif salbutamol",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hipertiroidisme, aneurisma, diabetes melitus, glaukoma sudut tertutup.",
    "EfekSamping": "Tremor otot terutama pada tangan, palpitasi, dan kram otot",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 43,
    "Kategori": "Obat Diare",
    "NamaObat": "ATAPULGIT",
    "Kemasan": "600 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_43,
    "Deskripsi": "Attapulgite adalah obat untuk meredakan diare. Walaupun termasuk dalam obat bebas yang bisa dibeli tanpa resep dokter, obat ini perlu digunakan sesuai anjuran dokter agar lebih aman.\nAttapulgite bekerja dengan cara memperlambat gerakan usus besar, membantu agar tinja lebih padat, dan mengurangi kram perut pada orang yang sedang mengalami diare. Obat ini juga mampu mengikat bakteri atau racun penyebab diare dan mengurangi kehilangan cairan.",
    "IndikasiUmum": "untuk diare akut dan keracunan makanan.",
    "Komposisi": "Attapulgit 600 mg",
    "Dosis": "Dewasa dan anak usia ≥12 tahun: 2 tablet setiap selesai buang air besar. Dosis maksimal adalah 12 tablet dalam sehari.\nAnak usia 6–12 tahun: 1 tablet setiap selesai buang air besar. Dosis maksimal adalah 6 tablet dalam sehari.",
    "AturanPakai": "Setelah BAB",
    "KontraIndikasi": "Hipersensitivitas,  disentri,  diare infeksius, Gagal ginjal, Gangguan liver, Obstruksi intestinal, Perdarahan saluran cerna, Hemofilia",
    "Perhatian": "Hindari penggunaan attapulgite pada anak di bawah usia 3 tahun. Attapulgite juga tidak boleh digunakan secara jangka panjang karena dapat menyebabkan konstipasi.\nAttapulgite sebaiknya tidak digunakan bersama dengan obat lain. Beri jarak setidaknya 2-3 jam antara penggunaan attapulgite dan obat lain.",
    "EfekSamping": "Sembelit, Mual, Perut kembung, Nyeri perut",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 44,
    "Kategori": "Obat Diare",
    "NamaObat": "PROBIOKID",
    "Kemasan": "",
    "BentukObat": "Sachet",
    "Golongan": "-",
    "GambarObat": img_44,
    "Deskripsi": "PROBIOKID SACHET mengandung probiotik yang digunakan untuk meredakan diare yang disebabkan oleh infeksi bakteri, membantu meredakan diare akibat penggunaan antibiotik, dan membantu meredakan peradangan pada sistem pencernaan.",
    "IndikasiUmum": "Suplemen untuk membantu memelihara kesehatan saluran cerna",
    "Komposisi": "Probiotik: L. helveticus R0052 (60%), B. infantis R0033 (20%), B. bifidum R0071 (20%) 3 x 10^9 CFU. Prebiotik:\" Fructo-oligosaccharides (FOS) 750 mg. Bahan tambahan: Vanilla Flavour, Potato Starch.",
    "Dosis": "1 x sehari 1 sachet, selama maksimal 10 hari",
    "AturanPakai": "Campurkan dengan makanan atau susu",
    "KontraIndikasi": "hipersensitivitas",
    "Perhatian": "Pasien yang dipasangi kateter vena sentral dan pasien pasca bedah, gangguan fungsi pankreas, diare akut berdarah terutama pada lansia dan bayi, pasien < dari 3 tahun dengan sindrom short bowel",
    "EfekSamping": "Memicu infeksi serius, memperburuk kondisi yang diderita pasien gangguan sistem kekebalan tubuh, gangguan GI",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 45,
    "Kategori": "Obat Diare",
    "NamaObat": "ORALIT",
    "Kemasan": "",
    "BentukObat": "Sachet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_45,
    "Deskripsi": "ORALIT SACHET merupakan obat dengan kandungan Natrium klorida, Kalium klorida, Trisodium sitrat dihidrat, Glukosa anhidrat dalam bentuk serbuk. Obat ini digunakan untuk mencegah dan mengobati kurang cairan (dehidrasi) akibat diare dan muntah.",
    "IndikasiUmum": "Obat ini digunakan untuk mencegah dan mengobati dehidrasi akibat diare dan muntah.",
    "Komposisi": "Tiap kantong 200 ml mengandung : - Natrium klorida 0.52 gram - Kalium klorida 0.3 gram - Trisodium sitrat dihidrat 0.58 gram - Glukosa anhidrat 2.7 gram",
    "Dosis": "Dibawah 1 tahun : 3 jam pertama 1.5 gelas, selanjutnya 1/2 gelas tiap kali mencret. \nAnak 1 - 5 tahun: 3 jam pertama 3 gelas, selanjutnya 1 gelas tiap kali mencret. \nAnak 5 - 12 tahun : 3 jam pertama 6 gelas, selanjutnya 1.5 gelas tiap kali mencret. \nAnak lebih dari 12 tahun : 3 jam pertama 12 gelas, selanjutnya 2 gelas tiap kali mencret.",
    "AturanPakai": "Dilarutkan dengan air matang. Larutan ini tidak dapat digunakan apabila lebih dari 24 jam. Jika terjadi muntah hentikan sementara, 2 sampai 5 menit, berikan oralit dengan sendok sedikit demi sedikit.",
    "KontraIndikasi": "Penderita gangguan fungsi ginjal, malabsorpsi glukosa, serta dehidrasi parah",
    "Perhatian": "Teruskan ASI, makan dan minuman selama diare, beri makanan ekstra setelah sembuh.Bila keadaan memburuk atau dalam 2 hari tidak membaik segera bawa ke RS / Puskesmas atau dokter dan oralit tetap diberikan.Jika terjadi gelala kekurangan garam natrium, dalam darah (hiponatremia), agar konsultasikan ke dokter / tenaga kesehatan terdekat.Hentikan Oralit jika diare berhenti dan pasien mulai membaik.",
    "EfekSamping": "Perut kembung, nyeri perut, hipernatremia",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 46,
    "Kategori": "Obat Asam Urat",
    "NamaObat": "ALLOPURINOL",
    "Kemasan": "100 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_46,
    "Deskripsi": "ALLOPURINOL 100 MG TABLET adalah obat generik yang digunakan untuk menurunkan kadar asam urat dalam darah. Obat ini bekerja dengan cara menghambat enzim xanthine oksidase sehingga menghambat pembentukan asam urat dan juga dapat menghambat sintesis purin. Enzim xanthine oksidase adalah enzim yang bertanggung jawab untuk oksidasi suatu zat alami dalam tubuh bernama hypoxanthine untuk menjadi xanthine, dan kemudian menjadi asam urat. Obat ini digunakan untuk pencegahan serangan gout kronis, mengobati sindrom lisis tumor dalam kemoterapi yang menyebabkan terjadinya hyperuricemia akut berat, mengobati batu ginjal dengan komponen asam urat dan kalsium oksalat (nefrolitiasis asam urat). Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Hiperurisemia primer: gout. Hiperurisemia sekunder: mencegah pengendapan asam urat dan kalsium oksalat. Produksi asam urat yang berlebihan antara lain disebakan karena polisitemia vera dan terapi sitostatik.",
    "Komposisi": "Allopurinol 100 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis awal 100 -300 mg/hari. Kondisi sedang: 300 - 600 mg/hari. Kondisi berat: 700 - 900 mg/hari. Dosis tunggal maksimum 300 mg.",
    "AturanPakai": "Diminum setelah makan",
    "KontraIndikasi": "Produk ini tidak boleh diberikan pada pasien dengan kondisi: Hipersensitif terhadap Allopurinol. Serangan asam urat akut.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Jangan diberikan pada keadaan gout akut. Hentikan pengobatan jika timbul ruam atau alergi kulit. Dapat mengganggu kemampuan mengemudi atau mengoperasikan mesin, sebiknya hindari kegiatan-kegiatan tersebut selama mengkonsumsi obat ini. Hati-hati pada pasien dengan gangguan fungsi ginjal, ibu hamil dan/atau menyusui.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Hipersensitivitas. Gangguan kulit dan jaringan subkutan: pruritus, urtikaria, alopecia. Gangguan gastrointestinal: Mual, muntah, diare, sakit perut, dispepsia, kehilangan indera perasa, gastritis. Mengantuk. Sakit kepala.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 47,
    "Kategori": "Obat Darah Tinggi",
    "NamaObat": "AMLODIPIN",
    "Kemasan": "5 MG & 10 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_47,
    "Deskripsi": "AMLODIPINE merupakan obat antihipertensi golongan Calcium Channel Blockers (CCB). Obat ini bekerja dengan cara menghambat kalsium masuk ke dalam sel sehingga salah satu efeknya adalah menyebabkan vasodilatasi, memperlambat laju jantung, dan menurunkan kontraktilitas miokard sehingga menurunkan tekanan darah. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "INFORMASI OBAT INI HANYA UNTUK KALANGAN MEDIS. Hipertensi, angina stabil kronik dan vasospastik.",
    "Komposisi": "Tiap tablet mengandung Amlodipine 5 mg & 10 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dosis awal 5 mg per hari. Maksimal 10 mg per hari.",
    "AturanPakai": "Dikonsumsi sebelum atau sesudah makan.",
    "KontraIndikasi": "Pasien yang hipersensitif terhadap amlodipine dan golongan dihydropirydine lainnya.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pengawasan ketat pada pasien gangguan fungsi hati dan gagal jantung kongestif.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Umum yang sering timbul: edema dan sakit kepala.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 48,
    "Kategori": "Obat Darah Tinggi",
    "NamaObat": "KAPTROPIL",
    "Kemasan": "12.5 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_48,
    "Deskripsi": "CAPTOPRIL 12.5 MG TABLET adalah obat antihipertensi yang termasuk golongan ACE inhibitor. Obat ini bekerja dengan menghambat perubahan angiotensin 1 menjadi angiotensin 2 sehingga terjadi vasodilatasi dan penurunan sekresi aldosteron. Vasodilatasi secara langsung akan menurunkan tekanan darah sedangkan berkurangnya aldosteron akan emnyebabkan ekskresi air dan natrium dan retensi kalium. Dalam menggunakan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "INFORMASI OBAT INI HANYA UNTUK KALANGAN MEDIS. Hipertensi, Gagal jantung pasien dengan tekanan darah normal",
    "Komposisi": "Captopril 12.5 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis awal 25-75 mg terbagi menjadi 2-3 dosis. Dosis dapat ditingkatkan hingga 100-150 mg terbagi menjadi 2-3 dosis setelah 2 minggu penggunaan.",
    "AturanPakai": "Diminum saat perut kosong, 1 jam sebelum makan atau 2 jam setelah makan.",
    "KontraIndikasi": "Hamil",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Ruam, pruritus, lesi seperti pemfigus yang reversibel, fotosensitivitas. Angioedema pada muka, proteinuria, neutropenia, anemia, trombositopenia.",
    "EfekSamping": "Pruritus, gangguan indera pengcapan, gangguan proteinuria, meningkatnya nilai nitrogen urea darah dan kreatinin, neutropenia.",
    "KategoriKehamilan": "Kategori D: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 49,
    "Kategori": "Obat Darah Tinggi",
    "NamaObat": "HIDROKLORTIAZID (HCT)",
    "Kemasan": "25 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_49,
    "Deskripsi": "HYDROCHLOROTHIAZIDE (HCT) 25 MG 10 TABLET digunakan sebagai obat anti hipertensi yang bekerja dengan cara mengurangi kemampuan ginjal untuk menyerap terlalu banyak natrium yang bisa menyebabkan retensi cairan. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "digunakan sebagai obat anti hipertensi yang bekerja dengan cara mengurangi kemampuan ginjal untuk menyerap terlalu banyak natrium yang bisa menyebabkan retensi cairan",
    "Komposisi": "Hydrochlorthiazide 25 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa untuk edema : 25 mg - 100 mg 1-2 kali sehari \nDewasa untuk Hipertensi : 25 mg 1 kali sehari",
    "AturanPakai": "sesudah makan",
    "KontraIndikasi": "hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. - pasien usia lanjut - penyakit ginjal berat, penyakit hati yang progresif",
    "EfekSamping": "Pusing\nSakit kepala\nFrekuensi buang air kecil makin sering\nSakit perut \nHilang nafsu makan\nRambut rontok",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 50,
    "Kategori": "Obat Darah Tinggi",
    "NamaObat": "FUROSEMID",
    "Kemasan": "40 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_50,
    "Deskripsi": "FUROSEMIDE 40 MG TABLET adalah obat golongan loop diuretik atau diuretik kuat. Diuretik merupakan kelompok obat yang akan meningkatkan jumlah urin yang keluar dari ginjal. Obat ini bekerja pada glomerulus ginjal dengan menghambat penyerapan kembali zat natrium oleh sel tubulus ginjal, sehingga terjadi peningkatan pengeluaran air, natrium, klorida, dan kalium tanpa mempengaruhi tekanan darah normal. Golongan obat diuretik kuat ini biasanya digunakan dalam terapi pada pasien yang mengalami gagal jantung. Dalam kondisi tersebut, terjadi penumpukan cairan yang disebabkan karena jantung yang tidak mampu memompa darah ke seluruh tubuh dengan baik. Selain itu, Furosemide juga digunakan untuk mengayasi pembengkakan atau edema yang disebabkan oleh kondisi penyakit hati dan penyakit ginjal. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Edema akibat gangguan jantung, hati,dan ginjal, serta hipertensi.",
    "Komposisi": "Furosemide 40 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nEdema: Dewasa: Dosis Awal: 40 mg per hari, \nHipertensi: \nLansia: dosis awal: 20 mg per hari, dan dapat ditingkatkan jika diperlukan. \nDewasa: 40-80 mg per hari sebagai terapi tunggal atau dikombinasikan dengan antihipertensi lain.",
    "AturanPakai": "Sebelum atau sesudah makan. Dapat diberikan bersama makanan untuk mengurangi rasa tidak nyaman pada gastrointestinal.",
    "KontraIndikasi": "Obat ini tidak boleh diberikan kepada pasien dengan kondisi: Hipersensitif terhadap Furosemide dan Sulfonamide. Anuria atau gagal ginjal. Memiliki penyakit Addison. Mengalami Hipovolema atau dehidrasi. Keadaan prekomatosa yang berhubungan dengan sirosis hati.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada pasien dengan kondisi: Pasien dengan pradiabetes atau diabetes mellitus. Sirosis hati. Gout. Gangguan berkemih. Berisiko mengalami penurunan tekanan darah yang parah. Gangguan ginjal dan hati. Lansia, anak, wanita hamil dan/atau menyusui.",
    "EfekSamping": "Pusing\nVertigo\nMual dan muntah\nDiare\nPenglihatan buram\nSembelit",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 51,
    "Kategori": "Obat Diabetes",
    "NamaObat": "METFORMIN",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_51,
    "Deskripsi": "METFORMIN 500 MG merupakan obat antidiabetes generik yang dapat mengontrol dan menurunkan kadar gula darah pada penderita diabetes tipe 2. Metformin termasuk ke dalam obat antidiabetes golongan Biguanide, yang bekerja dengan cara menghambat produksi glukosa (glukoneogenesis) di hati. Penghambatan tersebut mengakibatkan terjadinya penundaan absorbsi atau penyerapan glukosa di usus, sehingga menurunkan glukosa plasma baik basal maupun postprandial (setelah makan). Selain itu, Metformin juga bekerja dengan memperbaiki sensitivitas insulin dengan cara meningkatkan ambilan dan penggunaan glukosa di jaringan perifer. Dengan demikian, maka akan terjadi perbaikan toleransi glukosa pada pasien diabetes tipe 2. Obat ini dapat dikonsumsi secara tunggal, dikombinasikan dengan obat antidiabetes lain, atau diberikan bersama insulin. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Terapi awal untuk diabetes dewasa dengan keadaan kelebihan berat badan serta kadar gula darah yang tidak dapat dikendalikan hanya dengan diet saja. Terapi kombinasi untuk kegagalan terapi sulfonilurea primer atau sekunder. Terapi tambahan pada IDDM untuk mengurangi dosis insulin.",
    "Komposisi": "Metformin 500 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis awal: Sehari 3 x 500 mg. Dosis dapat ditingkatkan sebesar 500 mg tiap minggu. Dosis maksimal 2000 mg/hari. Jika terapi dengan 2.000 mg sekali sehari belum mencapai hasil yang optimal, dapat dipertimbangkan pemberian 2 x 1000 mg per hari. Dosis pemeliharaan: Sehari 2 x 850 mg.",
    "AturanPakai": "Bersamaan dengan makan",
    "KontraIndikasi": "Obat ini tidak boleh diberikan kepada pasien dengan kondisi: Penyakit ginjal dengan kadar kreatinin serum lebih dari 1.5 mg/dL (pria) dan lebih dari 1.4 mg/dL (wanita). Infark miokard akut, septikemia, gagal jantung kongestif. Penyakit hati kronik, alkoholik, hipoksia. Asidosis metabolik akut atau kronik, termasuk ketoasidosis dibetes dengan atau tanpa disertai koma. Wanta hamil.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Obat ini dapat mengganggu absorpsi Vitamin B12. Hati-hati penggunaan obat ini pada pasien dengan gangguan hati dan ginjal, konsumsi alkohol yang berlebihan, pasien lanjut usia, pasien dalam kondisi lemah dan malnutrisi, serta wanita hamil.",
    "EfekSamping": "Gangguan gastrointestinal (saluran cerna), pusing, sakit kepala, infeksi saluran napas atas, gangguan daya pengecapan.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 52,
    "Kategori": "Obat Diabetes",
    "NamaObat": "GLIBENKLAMID",
    "Kemasan": "5 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_52,
    "Deskripsi": "GLIBENCLAMIDE adalah obat anti diabetes tipe 2 yang termasuk golongan sulfonilurea. Glibenclamide atau Glyburide menurunkan kadar gula darah dengan cara meningkatkan kalsium intraseluler dalam sel beta pankreas sehingga menstimulasi produksi insulin. Glibenclamide da[at di gunakan sebagai terapi tunggal atau kombinasi dengan obat anti diabetes oral lainnya. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Diabetes Melitus tipe-2 (NIDDM) dimana kadar gula darah tidak dapat dikontrol secara adekuat dengan diet, olahraga dan penurunan berat badan saja.",
    "Komposisi": "Glibenclamide 5 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDosis awal: 2.5-5 mg/hari. Dosis dapat disesuaikan sebanyak 2.5 mg dengan interval mingguan sesuai dengan respon pasien. \nDosisi maksimal: 20 mg per hari. Dosis di atas 10 mg dapat diberikan dalam 2 dosis terbagi.",
    "AturanPakai": "Harus dikonsumsi bersama makanan. Diminum bersama sarapan atau makanan utama pertama pada hari itu.",
    "KontraIndikasi": "Obat ini tidak dianjurkan untuk : - Pasien yang hipersensitif terhadap sulfonilurea atau sulfonamida. - Pasien yang mengalami: diabetes melitus tipe 1, ketoasidosis diabetikum dengan atau tanpa koma, koma diabetik dan pra-koma, porfiria akut; infeksi parah, keadaan terkait stres (misalnya trauma, prosedur pembedahan). - Penderita gangguan ginjal dan hati ringan sampai sedang. - Pasien yang lemah dan kurang gizi. - Wanita menyusui. Kategori Kehamilan : B atau C tergantung pada produsennya. Kategori B: Mungkin dapat digunakan oleh wanita hamil. Penelitian pada hewan uji tidak memperlihatkan ada nya risiko terhadap janin, namun belum ada bukti penelitian langsung terhadap wanita hamil. Kategori C: Mungkin berisiko. Obat digunakan dengan hati-hati apabila besarnya manfaat yang diperoleh melebihi besarnya risiko terhadap janin. Penelitian pada hewan uji menunjukkan risiko terhadap janin dan belum terdapat penelitian langsung terhadap wanita hamil.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada pasien: - Defisiensi G6PD, insufisiensi adrenal atau hipofisis. - Kondisi yang meningkatkan risiko terjadinya hipoglikemia (misalnya selama olahraga berlebihan, waktu makan yang tidak teratur / terlewat, dan asupan kalori atau glukosa yang tidak mencukupi). - Penyakit kardiovaskular aterosklerotik. - Menjalani bypass lambung, gastrektomi lengan. - Gangguan hati dan ginjal ringan sampai sedang. - Pasien yang lemah dan kurang gizi. - Wanita menyusui.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: - Gangguan saluran cerna: Mual, mulas, diare, muntah, dispepsia. - Gangguan kulit dan jaringan subkutan: Ruam, pruritus, eritema, fotosensitifitas, porphyria cutanea tarda. - SIgnifikan: Hipoglikemia, anemia hemolitik (pada pasien defisiensi G6PD), penambahan berat badan.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 53,
    "Kategori": "Obat Diabetes",
    "NamaObat": "GLIMEPIRID",
    "Kemasan": "2 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_53,
    "Deskripsi": "GLIMEPIRIDE TABLET merupakan obat anti diabetes yang termasuk ke dalam golongan sulfonilurea. Glimepiride digunakan sebagai obat anti diabetes mellitus tipe 2 atau Non-Insulin-Dependent (type II) Diabetes Melitus (NIDDM) dimana kadar glukosa darah tidak dapat hanya dikontrol dengan diet dan olahraga saja. Glimepiride bekerja menurunkan kadar gula darah dengan merangsang pelepasan insulin dari sel beta pankreas yang masih berfungsi. Obat ini juga dapat meningkatkan sensitivitas jaringan perifer terhadap insulin. GLIMEPIRIDE digunakan sebagai terapi tunggal atau dikombinasikan dengan obat anti diabetes oral lain atau insulin. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Diabetes Melitus Tipe 2 atau Non-Insulin-Dependent (type II) Diabetes Melitus (NIDDM) dimana kadar glukosa darah tidak dapat hanya dikontrol dengan diet dan olahraga saja.",
    "Komposisi": "Tiap tablet mengandung Glimepiride 2 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. - Dosis awal: 1-2 mg, satu kali sehari - Dosis pemeliharaan: 1-4 mg, satu kali sehari, dosis maksimum 8 mg, satu kali sehari. - Pada saat pemberian telah mencapai dosis 2 mg maka kenaikan dosis tidak boleh melebihi 2 mg dengan interval 1-2 minggu tergantung dari respon gula darah.",
    "AturanPakai": "Berikan segera sebelum makan utama yang pertama pada hari yang sama. Jangan mengurangi jadwal makan.",
    "KontraIndikasi": "Hipersensitivitas. Pasien ketoasidosis diabetik, dengan atau tanpa koma.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. - Risiko hipoglikemia - Tidak terkendalinya kadar glukosa darah: Bila seorang pasien, yang kondisi penyakit DM-nya stabil dengan menggunakan regimen antidiabetik tertentu, terpapar stress seperti demam, trauma, infeksi, pembedahan, kadar gluosa darah bisa tidak terkendali. Dalam keadaan seperti ini, dibutuhkan kombinasi insulin dengan glimipiride atau pengobatan tunggal dengan insulin. - Pentingnya untuk melakukan diet, program olah raga secara teratur dan pemeriksaan glukosa darah secara teratur. - Glimepiride tidak dianjurkan pada kehamilan. - Pada ibu menyusui penggunaan glimipiride sebaiknya tidak dilanjutkan. - Keamanan dan efektivitas pada anak-anak belum diketahui.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: - Gangguan pada saluran cerna, seperti muntah, nyeri lambung dan diare. - Reaksi alergi, bersifat sementara dan akan hilang meskipun penggunaan glimipiride dilanjutkan, jika tetap terjadi maka penggunaan glimepiride harus dihentikan. - Gangguan metabolisme berupa hiponatremia. - Perubahan pada akomodasi dan/atau kaburnya penglihatan. - Reaksi hematologik seperti leukopenia, agranulositosis, trombositopenia, anemia hemolitik, anemia aplastik, dan pansitopenia.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 54,
    "Kategori": "Obat Jantung",
    "NamaObat": "ISOSORBID DINITRAT",
    "Kemasan": "5 MG",
    "BentukObat": "Tablet Sublingual",
    "Golongan": "Obat Keras",
    "GambarObat": img_54,
    "Deskripsi": "ISOSORBIDE DINITRATE 5 MG TABLET merupakan obat anti angina golongan nitrat. Obat ini bekerja untuk menurunkan kebutuhan dan meningkatkan suplai oksigen dengan cara mempengaruhi tonus vaskular. Obat ini digunakan untuk mengatasi Angina (nyeri dada) yang disebabkan oleh penyakit jantung. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pencegahan dan pengobatan angina pektoris yang disebabkan penyakit jantung koroner",
    "Komposisi": "Isosorbide Dinitrate 5 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Dosis awal: 5 mg, dapat ditingkatkan 10 mg pada hari ke-2 atau ke-3 sesuai anjuran dokter. Dosis pemeliharaan: 1 tablet 2 kali per hari.",
    "AturanPakai": "Dihisap dibawah lidah",
    "KontraIndikasi": "Infark miokard akut, hipotensi, syok, hipovolemia, trauma serebral, anemia.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. - Glaukoma sudut tertutup, hipotiroid, malnutrisi, hipotermia, sirkulasi darah yang tidak stabil. - Anak-anak",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Sakit kepala, vasodilatasi kutaneus, hipotensi postural, ruam kulit.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Tablet Sublingual"
  },
  {
    "No": 55,
    "Kategori": "Obat Pengencer Darah",
    "NamaObat": "ASAM ASETILSALISILAT (MINIASPI)",
    "Kemasan": "80 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_55,
    "Deskripsi": "MINIASPI 80 MG TABLET adalah obat tablet yang mengandung Acetylsalicylic Acid 80 mg. Acetylsalicylic acid atau dikenal juga dengan Aspirin merupakan senyawa analgesik non steroid yang digunakan sebagai analgesik, antipiretik, antiinflamasi dan anti-platelet. Pada dosis kecil (80 mg - 100 mg), Acetylsalicylic acid, memiliki manfaat sebagai anti-platelet atau pengencer darah yang dapat digunakan untuk mencegah proses agregasi platelet (keping darah atau trombosit) pada pasien yang mengalami infark miokard atau penyumbatan pada otot jantung dan kondisi pasca stroke. Obat ini bekerja dengan cara menghambat agregasi trombosit sehingga dapat menghambat pembentukan trombus (penggumpalan darah yang terbentuk pada dinding pembuluh darah) yang sering ditemukan pada pembuluh darah arteri. Acetylsalicylic acid akan menghambat aktivitas enzim cyclo-oxygenase I dan II (COX 1 dan COX 2) yang selanjutnya menghambat produksi tromboksan, yaitu zat yang merangsang agregasi trombosit. Dalam penggunaan obat ini HARUS SESUAI DENGAN PENTUNJUK DOKTER",
    "IndikasiUmum": "Mencegah proses agregasi trombosit pada pasien infark miokard dan pasien angina tidak stabil, serta mencegah serangan serebral iskemik sesaat.",
    "Komposisi": "Acetylsalicylic acid 80 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. 80-160 mg, diberikan 1 kali per hari.",
    "AturanPakai": "Diberikan setelah makan. Telan utuh, jangan dikunyak, dipotong, atau dihancurkan.",
    "KontraIndikasi": "Obat ini tidak boleh diberikan kepada pasien dengan kondisi: Hipersensitif terhadap aspirin dan obat antiinflamasi non steroid (AINS) lainnya. Asma, rhinitis, dan polip hidung. Memiliki riwayat atau sedang mengalami tukak lambung. Pendarahan subkutan, homofilia, atau trombositopenia. Menerima terapi antikoagulan, atau AINS, atau Methotrexate. Gangguan hati atau ginjal yang berat. Anak-anak di bawah usia16 tahun dan sedang dalam proses pemulihan dari infeksi virus.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Hati-hati penggunaan obat ini pada pasien dengan: Gangguan ginjal atau hati moderate. Penderita dispepsia atau lesi pada mukosa gastrointestinal atau tukak lambung aktif. Gangguan asma atau alergi, anemia, dehidrasi, menoragia, hipertensi tidak terkontrol, defisiensi G6PD, tirotoksikosis. Pasien yang menjalani prosedur pembedahan. Wanita hamil dan/atau menyusui. Anak di bawah usia 12 tahun.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Iritasi dan pendarahan saluran pencernaan (gastrointestineal), mual, muntah, tukak lambung, dispnea, reaksi kulit, trombositopenia.",
    "KategoriKehamilan": "Kategori D: Terbukti berisiko terhadap janin. Meski demikian, obat masih dapat digunakan jika obat diperlukan untuk mengatasi keadaan yang mengancam jiwa, atau penyakit serius, dimana obat yang lebih aman tidak dapat digunakan atau tidak efektif.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 56,
    "Kategori": "Obat Lambung",
    "NamaObat": "ANTASIDA DOEN",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_56,
    "Deskripsi": "ANTASIDA DOEN merupakan obat sakit maag dengan kandungan Alumunium Hydroxide dan Magnesium Hydroxide. Kombinasi Alumunium Hydroxide dan Magnesium Hydroxide bekerja menetralkan asam lambung dan menginaktifkan pepsin, sehingga dapat digunakan untuk mengurangi gejala yang berhubungan dengan kelebihan asam lambung, nyeri ulu hati akibat iritasi oleh asam lambung, kembung dan perasaan penuh pada lambung.",
    "IndikasiUmum": "Obat sakit maag untuk mengurangi nyeri lambung yang disebabkan oleh kelebihan asam lambung dengan gejala seperti mual dan perih",
    "Komposisi": "Alumunium Hydroxide 200 mg, Magnesium Hydroxide 200 mg",
    "Dosis": "Dewasa : 1-2 tablet, 3-4 kali per hari. \nAnak (6-12 tahun) : 0.5-1 tablet, 3-4 kali per hari.",
    "AturanPakai": "Harus dikonsumsi 15-30 menit sebelum makan. Kunyah tablet degan baik sebelum ditelan.",
    "KontraIndikasi": "Disfungsi ginjal berat, hipersensitif",
    "Perhatian": "Pasien yg sedang menjalani diet rendah fosfor. Jangan digunakan selama >2 minggu. \nAnak <6 thn.",
    "EfekSamping": "Konstipasi, diare, mual, muntah",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 57,
    "Kategori": "Obat Lambung",
    "NamaObat": "ANTASIDA DOEN",
    "Kemasan": "",
    "BentukObat": "Suspensi",
    "Golongan": "Obat Bebas",
    "GambarObat": img_57,
    "Deskripsi": "ANTASIDA DOEN merupakan obat dengan kandungan Alumunium Hydroxide dan Magnesium Hydroxide. Obat ini digunakan untuk mengatasi maag dengan gejala nyeri ulu hati, sering bersendawa, dan perut kembung.",
    "IndikasiUmum": "Mengurangi gejala kelebihan asam lambung, gastritis, tukak lambung, tukak usus dua belas jari",
    "Komposisi": "Alumunium Hydroxide 200 mg, Magnesium Hydroxide 200 mg",
    "Dosis": "Dewasa : 3-4 kali sehari 1-2 sendok takar \nAnak 6-12 tahun : 3-4 kali sehari 1/2-1 sendok takar",
    "AturanPakai": "Harus dikonsumsi 15-30 menit sebelum makan",
    "KontraIndikasi": "Disfungsi ginjal berat",
    "Perhatian": "obat ini mungkin dapat berinteraksi dengan obat lain oleh karena itu beri jeda apabila akan mengkonsumsi obat lain, jangan digunakan dalam jangka waktu yang lama, hati-hati penggunaan pada penderita gangguan ginjal",
    "EfekSamping": "Sembelit, diare, mual, muntah",
    "KategoriKehamilan": "Kategori N: Belum dikategorikan",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 58,
    "Kategori": "Obat Lambung",
    "NamaObat": "RANICHO (RANITIDIN)",
    "Kemasan": "75 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Keras",
    "GambarObat": img_58,
    "Deskripsi": "Ranicho adalah obat dengan kandungan ranitidine yang dikemas dalam sediaan sirup dengan varian rasa strawberry. Ranicho digunakan untuk menangani gejala dan penyakit akibat produksi asam lambung yang berlebihan, seperti tukak lambung, tukak duodenum, hiperasiditas (sekresi berlebihan dari asam klorida (HCl) pada lambung, menyebabkan terjadinya erosi pada dinding lambung), gastritis (peradangan pada dinding lambung), refluks esofagitis (masuknya kembali makanan yang sudah beradadalam perut, ke dalam kerongkongan bagian bawah).",
    "IndikasiUmum": "Terapi jangka pendek untuk tukak duodenal atau tukak lambung aktif, meredakan gejala-gejala refluks esofagitis. Terapi pemeliharaan sesudah penyembuhan tukak duodenal atau tukak lambung. Terapi untuk kondisi hipersekresi patologis seperti sindrom Zollinge",
    "Komposisi": "Per 5 mL : Ranitidine HCl 75 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa: 2 kali sehari 150 mg (10 mL) \nAnak: 2-4 mg/kgBB 2 kali sehari",
    "AturanPakai": "Diminum sebelum 30 menit sebelum makan. Sirup ini tidak boleh dicampur dengan sediaan cair lainnya.",
    "KontraIndikasi": "Hindari pemberian pada pasien porfiria (Kelainan darah)",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Ibu hamil dan menyusui, anak, gangguan fungsi ginjal dan hati, porfiria akut.",
    "EfekSamping": "Sakit kepala, lemah, konstipasi, diare, mual, nyeri perut, ruam kulit.",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 59,
    "Kategori": "Obat Lambung",
    "NamaObat": "OMEPRAZOL",
    "Kemasan": "20 MG",
    "BentukObat": "Kapsul",
    "Golongan": "Obat Keras",
    "GambarObat": img_59,
    "Deskripsi": "OMEPRAZOLE merupakan obat golongan proton pump inhibitor yang dgunakan untuk menurunkan produksi asam berlebih pada lambung. Obat ini sering digunakan untuk mengatasi tukak pada lambung dan usus, serta reflux esofagitis. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Pengobatan jangka pendek untuk tukak duodenal, tukak lambung, refluks esofagitis, sindrom Zollinger-Ellison",
    "Komposisi": "Omeprazole 20 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 20 - 40 mg per hari selama 2-4 minggu",
    "AturanPakai": "Dikonsumsi 30 menit sebelum makan",
    "KontraIndikasi": "Omeprazole dikontraindikasikan untuk pasien yang diketahui hipersensitivitas terhadap obat ini atau bahan lain yang terdapat dalam formulasi.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Penderita dengan gangguan fungsi ginjal. \n- Wanita hamil, menyusui dan anak- anak usia kurang dari 1 tahun.",
    "EfekSamping": "Gangguan gastritis, sakit kepala, ruam kulit",
    "KategoriKehamilan": "Kategori B: Mungkin dapat digunakan oleh wanita hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 60,
    "Kategori": "Obat Kolesterol",
    "NamaObat": "SIMVASTATIN",
    "Kemasan": "20 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_60,
    "Deskripsi": "SIMVASTATIN merupakan senyawa antilipermic derivat asam mevinat golongan statin, yang digunakan sebagai obat penurun kolesterol. Obat ini bekerja sebagai inhibitor kompetitif pada HMG-CoA reduktase (enzim yang mempercepat proses sintesis atau produksi kolesterol). Obat ini bekerja dengan cara menghambat 3-hydroxy-3-methylglutaryl-coenzyme A (HMG-CoA) reductase, yaitu suatu enzim yang berperan dalam pembentukan kolesterol total, kolesterol LDL (kolesterol jahat), dan kadar trigliserida. Dengan terhambatnya kinerja enzim ini, maka akan terjadi penurunan pembentukan kolesterol di hati, serta meningkatkan reseptor LDL pada permukaan sel hati, sehingga terjadi peningkatan ambilan dan katabolisme kolesterol LDL. Dengan demikian, jumlah kolesterol total, kolesterol LDL (kolesterol jahat), dan kadar trigliserida akan menurun, dan meningkatkan jumlah HDL, sehingga menurunkan risiko aterosklerosis. Dalam penggunaan obat ini harus SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Menurunkan kadar kolesterol total dan LDL pada penderita hiperkolesterolemia",
    "Komposisi": "Simvastatin 20 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa: 10-40 mg, satu kali sehari.",
    "AturanPakai": "Sesudah makan pada malam hari, hindari konsumsi jus anggur berlebihan (> 1 L/hari)",
    "KontraIndikasi": "Obat ini tidak boleh diberikan kepada pasien dengan kondisi: Hipersensitif terhadap simvastatin atau komponen obat. Gagal fungsi hati atau pernah mengalami gagal fungsi hati. Peningkatan jumlah serum transaminase yang abnormal. Pecandu alkohol. Wanita hamil dan menyusui.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Selama terapi dengan Simvastatin harus dilakukan pemeriksaan fungsi hati. Pemeriksaan level transaminase dalam serum, termasuk ALT (SGPT) harus dilakukan sebelum pengobatan, setiap 6 minggu dan 12 minggu setelah pengobatan atau kenaikan dosis dan kemudian secara berkala setiap 6 bulan. Pemberian Simvastatin harus dihentikan bagi pasien yang mengalami peningkatan serum transaminase 3 kali lebih besar di atas normal. Bila mengalami nyeri otot, lemah atau lemas, sebaiknya segera melaporkannya kepada dokter Anda. Segera hentikan pengobatan bila terjadi peningkatan kadar creatinine phosphokinase. Pengobatan dengan HMG-CoA reduktase inhibitor harus ditunda atau dihentikan pada penderita dengan gejala akut dan serius yang cenderung mengarah ke myopathy, atau gagal ginjal akut sekunder karena adanya rhabdomyolisis. Keamanan dan efektifitas penggunaan Simvastatin pada anak-anak dan remaja belum diketahui, karena itu pemberian Simvastatin tidak dianjurkan.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Sakit kepala, konstipasi, mual, diare, dispepsia, sakit perut, nyeri dada, ruam kulit, rhabdomyolisis, miopati.",
    "KategoriKehamilan": "Kategori X: Obat ini dikontraindikasikan untuk wanita hamil dan yang berkemungkinan untuk hamil.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 61,
    "Kategori": "Obat Mual/Muntah",
    "NamaObat": "DOMPERIDON",
    "Kemasan": "10 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Keras",
    "GambarObat": img_61,
    "Deskripsi": "DOMPERIDONE 10 MG TABLET adalah obat yang digunakan untuk menghilangkan mual dan muntah, terutama yang disebabkan terapi sitotoksik. Domperidone bekerja dengan menghambat aksi dopamin pada reseptornya dalam CTZ (Chemoreseptor Trigger Zone) yang berada pada bagian luar sawar darah otak yang meregulasi mual dan muntah. Domperidone juga dapat mengatasi mual muntah akibat penggunaan kontrasepsi hormonal darurat. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "INFORMASI OBAT INI HANYA UNTUK KALANGAN MEDIS. Terapi mual dan muntah (termasuk yang disebabkan karena levodopa dan bromokriptin, kemoterapi atau radioterapi kanker), dispepsia fungsional",
    "Komposisi": "Domperidone 10 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 3 kali sehari 1 tablet, maksimal 30 mg per hari \nAnak : 3 x sehari 0.25-0,5 mg/kgBB per hari.",
    "AturanPakai": "Sebaiknya diberikan pada saat perut kosong : Berikan 15-30 menit sebelum makan.",
    "KontraIndikasi": "Jika stimulasi terhadap motilitas lambung dianggap membahayakan. Tumor hipofisis. Prolaktinoma. Gangguan elektrolit yang signifikan (misalnya hipokalemia, hipomagnesemia, hiperkalemia).",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Konsultasikan kepada dokter apabila anda memiliki kondisi gangguan ginjal, hamil, dan menyusui. Tidak dianjurkan untuk profilaksis rutin pada muntah paska bedah atau penggunaan jangka panjang. Tidak dianjurkan penggunaan pada bayi kurang dari 1 tahun.",
    "EfekSamping": "Ruam dan reaksi alergi lain, kadar prolaktin naik (kemungkinan galaktorea dan ginekomasti).",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 62,
    "Kategori": "Obat Mual/Muntah",
    "NamaObat": "DOMPERIDON",
    "Kemasan": "5 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Keras",
    "GambarObat": img_62,
    "Deskripsi": "DOMPERIDONE SIRUP merupakan obat yang digunakan untuk mengatasi mual dan muntah, terutama yang disebabkan terapi sitotoksik. Domperidone bekerja dengan menghambat aksi dopamin pada reseptornya dalam CTZ (Chemoreseptor Trigger Zone) yang berada pada bagian luar sawar darah otak. CTZ berperan dalam regulasi mual dan muntah. Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "- Pengobatan mual dan muntah akut. Tidak dianjurkan pencegahan rutin pada muntah setelah operasi. \n- Pengobatan mual dan muntah yang disebabkan oleh pemberian levodopa dan bromocriptine. \n- Pengobatan gejala dispepsia fungsional.\n- Anak-anak : Tidak dianjurkan, kecuali untuk mual dan muntah yang disebabkan oleh kemoterapi kanker dan radioterapi.",
    "Komposisi": "Per 5 ml : Domperidone 5 mg",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. \nDewasa : 10 mg (10ml), 3 kali sehari.\nAnak-anak: 3 x sehari 0.25-0,5 mg/kgBB per hari.",
    "AturanPakai": "Sebaiknya diberikan pada saat perut kosong : Berikan 15-30 menit sebelum makan.",
    "KontraIndikasi": "Pasien hipersensitif terhadap domperidone. Pasien dengan prolaktinoma tumor hipofise yang mengeluarkan prolaktin. Pada pasien di mana peningkatan motilitas lambung dapat membahayakan misalnya: adanya pendarahan, perforasi atau obstruksi mekanik.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Hati-hati diberikan pada wanita hamil dan menyusui. \n- Tidak dianjurkan untuk pemberian jangka panjang. \n- Hati-hati penggunaan pada pasien dengan gangguan fungsi hati dan ginjal. \n- Hati-hati penggunaan pada bayi berusia kurang dari 1 tahun, karena kemungkinan sawar darah otak belum berkembang sempurna.",
    "EfekSamping": "Ruam dan reaksi alergi lain, kadar prolaktin naik (kemungkinan galaktorea dan ginekomasti).",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 63,
    "Kategori": "Multivitamin",
    "NamaObat": "VITAMIN B1",
    "Kemasan": "50 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_63,
    "Deskripsi": "Vitamin B1 atau tiamin adalah vitamin yang berperan dalam penggunaan karbohidrat menjadi sumber energi bagi tubuh. Tiamin juga membantu menjaga fungsi saraf agar tetap baik. Asupan vitamin B1 harian tubuh bisa didapatkan melalui makanan atau suplemen.",
    "IndikasiUmum": "Memenuhi kebutuhan vitamin B1 dan mengobati penyakit akibat kekurangan vitamin b1",
    "Komposisi": "Thiamin HCl 50 mg",
    "Dosis": "Mengatasi kekurangan vitamin B1\nDewasa: dosis maksimal 300 mg tablet vitamin B1 per hari.\nAnak-anak: 10­­–50 mg tablet vitamin B1 per hari, diberikan dalam dosis terpisah.\n\nMencegah kekurangan vitamin B1\nDewasa: 50–100 mg tablet vitamin B1 sekali sehari.\nAnak-anak: 0,5–1 mg tablet vitamin B1 sekali sehari.",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "-",
    "Perhatian": "HARUS DENGAN RESEP DOKTER.",
    "EfekSamping": "-",
    "KategoriKehamilan": "BILA DOSIS TIDAK MELEBIHI ANGKA KECUKUPAN GIZI\nKategori A: Pada wanita hamil tidak menunjukkan adanya risiko terhadap janin, dan kecil kemungkinannya untuk membahayakan janin.\n\nBILA DOSIS MELEBIHI ANGKA KECUKUPAN GIZI\nKategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 64,
    "Kategori": "Multivitamin",
    "NamaObat": "VITAMIN B6",
    "Kemasan": "10 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_64,
    "Deskripsi": "VITAMIN B6 merupakan obat vitamin generik yang bermanfaat untuk mencegah defisiensi vitamin B6 dan anemia sideroblastik.Vitamin B6 (Pyridoxine) memiliki mekanisme kerja sebagai berikut : Pyridoxine HCl (Vitamin B6) berperan untuk mengubah makanan yang dikonsumsi menjadi energi, memproduksi sel darah merah, dan menjaga kerja jaringan saraf.",
    "IndikasiUmum": "Defisiensi vitamin B6",
    "Komposisi": "Vitamin B6 10 mg",
    "Dosis": "Defisiensi B6 : \nDewasa = Dosis sesuai kebutuhan pasien. Dapat hingga 150 mg per hari.",
    "AturanPakai": "Sebelum atau sesudah makan",
    "KontraIndikasi": "-",
    "Perhatian": "HARUS DENGAN RESEP DOKTER.",
    "EfekSamping": "-",
    "KategoriKehamilan": "Kategori A: Pada wanita hamil tidak menunjukkan adanya risiko terhadap janin, dan kecil kemungkinannya untuk membahayakan janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 65,
    "Kategori": "Multivitamin",
    "NamaObat": "VITAMIN B KOMPLEK",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_65,
    "Deskripsi": "VITAMIN B COMPLEX merupakan tablet yang digunakan untuk memenuhi kebutuhan vitamin B kompleks di tubuh. Vitamin B kompleks berperan dalam proses metabolisme karbohidrat dan protein dalam tubuh dalam menghasilkan energi.",
    "IndikasiUmum": "Membantu memenuhi kebutuhan vitamin B kompleks",
    "Komposisi": "vitamin B1, vitamin B2, vitamin B6, calcium pethonate, nicotinamide",
    "Dosis": "3 kali sehari 1 tablet",
    "AturanPakai": "sesudah makan",
    "KontraIndikasi": "-",
    "Perhatian": "HARUS DENGAN RESEP DOKTER.",
    "EfekSamping": "-",
    "KategoriKehamilan": "-",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 66,
    "Kategori": "Multivitamin",
    "NamaObat": "HORVITA-G",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "-",
    "GambarObat": img_66,
    "Deskripsi": "Horvita G adalah suplemen yang diproduksi oleh PT. Samco Farma dalam bentuk sediaan kaplet. Horvita G mengandung zat aktif Asam folat, vitamin A, vitamin D, vitamin B1, vitamin B6, vitamin B2, vitamin B12, vitamin C, vitamin E, nikotinamida, kolina bitartrat, royal jelly, kalsium pantotenat, besi (II) sulfat, kalium iodida, tembaga (II) sulfat, mangan sulfat, dikalsium fosfat, magnesium sulfa, seng sulfat, ginseng, dan natrium klorida. Horvita G digunakan untuk membantu menambah nafsu makan, membantu meningkatkan kesehatan, dan meningkatkan kekuatan akibat kelelahan. Horvita G juga dapat digunakan untuk membantu memulihkan kesehatan setelah sakit.",
    "IndikasiUmum": "untuk membantu menambah nafsu makan, membantu meningkatkan kesehatan dan meningkatkan stamina akibat kelelahan. Horvita G juga dapat digunakan untuk membantu memulihkan kesehatan setelah sakit.",
    "Komposisi": "Asam folat, vitamin A, vitamin D, vitamin B1, vitamin B6, vitamin B2, vitamin B12, vitamin C, vitamin E, nikotinamida, kolina bitartrat, royal jelly, kalsium pantotenat, besi (II) sulfat, kalium iodida, tembaga (II) sulfat, mangan sulfat, dikalsium fosfat, magnesium sulfa, seng sulfat, ginseng, dan natrium klorida",
    "Dosis": "1 kaplet, sebanyak 1-3 kali sehari.",
    "AturanPakai": "sesudah makan",
    "KontraIndikasi": "Hindari penggunaan Horvita G pada pasien yang memiliki indikasi hipersensitif.\nTidak boleh diberikan pada anak-anak.\nTidak boleh diberikan pada ibu hamil dan menyusui.",
    "Perhatian": "Penderita diabetes dan hipertensi.",
    "EfekSamping": "-",
    "KategoriKehamilan": "-",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 67,
    "Kategori": "Multivitamin",
    "NamaObat": "PERVITAL",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_67,
    "Deskripsi": "Fervital adalah suplemen yang diproduksi oleh Itrasal. Fervital mengandung Besi (II) fumarat, Vitamin A, Vitamin B1, Vitamin B2, Vitamin B6, Vitamin B12, Nikotinamida, Tembaga (II) Sulfat, Kalsium Pantotenat, Magnesium Oksida, Mangan Sulfat, Seng Sulfat. Fervital digunakan untuk mengatasi kekurangan vitamin dan mineral, kekurangan darah, masa penyembuhan penyakit, saat hamil, menyusui, menopause dan masa pertumbuhan anak.",
    "IndikasiUmum": "untuk mengatasi kekurangan vitamin dan mineral, kekurangan darah, masa penyembuhan penyakit, saat hamil, menyusui, menopause dan masa pertumbuhan anak.",
    "Komposisi": "Besi (II) fumarat 200 mg, Vitamin A 4000 Sl, Vitamin B1 10 mg, Vitamin B2 10 mg, Vitamin B6 2 mg, Vitamin B12 5 g, Nikotinamida 20 mg, Tembaga (II) Sulfat 1 mg, Kalsium Pantotenat 5 mg, Magnesium Oksida 1 mg, Mangan Sulfat 1 mg, Seng Sulfat 1,5 mg",
    "Dosis": "1 Kapsul per hari",
    "AturanPakai": "Sesudah makan",
    "KontraIndikasi": "Hindari pemberian pada pasien yang hipersensitif (reaksi berlebihan atau sangat sensitif) terhadap kandungan dalam suplemen tersebut.",
    "Perhatian": "-",
    "EfekSamping": "Konstipasi (sembelit)\nMual\nDiare\nKram perut",
    "KategoriKehamilan": "-",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 68,
    "Kategori": "Multivitamin",
    "NamaObat": "NEUROVIT-E",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_68,
    "Deskripsi": "NEUROVIT E merupakan multivitamin yang mengandung Vitamin B1, Vitamin B6, Vitamin B12 dan Vitamin E. Multivitamin ini digunakan untuk mengatasi radang saraf, kelumpuhan saraf, parestesia, nyeri punggung, nyeri saraf, kesemutan, lesu dan gangguan neuropati. Vitamin B1 memberikan energi pada sel saraf agar berfungsi dengan baik. Vitamin B6 berperan dalam memfasilitasi transmisi impuls saraf. Vitamin B12 berperan dalam pembentukan selubung myelin yang melindungi saraf serta mempengaruhi kecepatan transmisi sinyal. Vitamin E merupakan antioksidan yang berfungsi mencegah pembentukan spesies oksigen reaktif. Spesies oksigen reaktif ini dapat menyebabkan kerusakan protein dan DNA pada jaringan.",
    "IndikasiUmum": "Multivitamin ini digunakan untuk mengatasi radang saraf, kelumpuhan saraf, parestesia, nyeri punggung, nyeri saraf, kesemutan, lesu dan gangguan neuropati.",
    "Komposisi": "Vitamin B1 100 mg, Vitamin B6 200 mg, Vitamin B12 200 mcg, Vitamin E 50 mg",
    "Dosis": "1 tablet per hari",
    "AturanPakai": "Diberikan sesudah makan",
    "KontraIndikasi": "Pasien yang hipersensitif terhadap komponen obat ini.",
    "Perhatian": "Disimpan dalam wadah kering dan terhindar dari sinar matahari langsung.",
    "EfekSamping": "Tidak menimbulkan efek samping jika digunakan sesuai anjuran.",
    "KategoriKehamilan": "-",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 69,
    "Kategori": "Multivitamin",
    "NamaObat": "SANBE KIDS",
    "Kemasan": "",
    "BentukObat": "Sirup",
    "Golongan": "Obat Bebas",
    "GambarObat": img_69,
    "Deskripsi": "Sanbe Kids adalah sediaan vitamin yang diproduksi oleh Sanbe Farma. Sanbe Kids mengandung Vitamin A, vitamin D, vitamin B1, vitamin B2, vitamin B6, vitamin B12, nicotinamide, kolin, L-lisin HCl, Ca hypophosphite, minyak hati ikan kod. Sanbe Kids juga memiliki kandungan ekstrak curcuma yang digunakan untuk memenuhi kebutuhan vitamin, dan meningkatkan daya tahan tubuh, serta meningkatkan nafsu makan.",
    "IndikasiUmum": "Suplementasi vit, kalsium, & L-lysine. Memperbaiki nafsu makan.",
    "Komposisi": "Per 15 mL Vit A 850 IU, vit D 100 IU, vit B1 3 mg, vit B2 2 mg, vit B6 5 mg, vit B12 5 mcg, nicotinamide 3 mg, choline 12 mg, L-lysine HCl 100 mg, Ca hypophosphite 500 mg, cod liver oil 8 mg, curcuma extr 12 mg.",
    "Dosis": "Anak 6-12 thn 1 sdm (15 mL) 2 x/hr; \n1 thn - <6 thn 1 sdm (15 mL) 1 x/hr; \n6 bln -<1 thn 1/2 sdm (7.5 mL) 1 x/hr",
    "AturanPakai": "Sesudah Makan",
    "Kontra indikasi": "Tidak boleh diberikan pada pasien yang memiliki riwayat hipersensitif terhadap komposisi dari Sanbe Kids.",
    "Perhatian": "-",
    "EfekSamping": "-",
    "KategoriKehamilan": "-",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 70,
    "Kategori": "Multivitamin",
    "NamaObat": "ZINK",
    "Kemasan": "20 MG/5 ML",
    "BentukObat": "Sirup",
    "Golongan": "Obat Bebas Terbatas",
    "GambarObat": img_70,
    "Deskripsi": "ZINC SULFATE SIRUP digunakan sebagai terapi komplementer untuk cairan rehidrasi oral untuk mengganti cairan tubuh yang hilang dan mencegah dehidrasi pada anak.",
    "IndikasiUmum": "Pelengkap ORS yang diperlukan untuk pengganti cairan tubuh dan pencegahan dehidrasi pada anak, dan digunakan bersama ORS (Oral Rehydration Salt)",
    "Komposisi": "ZINC SULFATE",
    "Dosis": "Bayi 2-6 bulan : 1/2 sendok takar (10mg) setiap hari selama 10 hari berturut-turut (meskipun diare sudah berhenti), \nanak-anak 6 bulan-5tahun : 1 sendok takar (20 mg) setiap hari selama 10 hari berturut-turut (meskipun diare sudah berhenti).",
    "AturanPakai": "Sebelum makan (perut kosong)",
    "KontraIndikasi": "Hipersensitif",
    "Perhatian": "Selama diare masih berlangsung, selain diberikan suplementasi Zinc, juga diberikan ORS, para ibu menyusui dianjurkan tetap menyusui atau meningkatkan frekuensi menyusui pada anak selama dan setelah diare.",
    "EfekSamping": "Dosis tinggi Zinc untuk periode yang lama dapat menyebabkan penurunan konsentrasi lipoprotein plasma dan penurunan absorpsi tembaga.",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 71,
    "Kategori": "Multivitamin",
    "NamaObat": "CALCIUM LACTAT",
    "Kemasan": "500 MG",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_71,
    "Deskripsi": "CALCIUM LACTATE merupakan obat yang digunakan untuk pengobatan kekurangan kalsium, serta memelihara kesehatan tulang dan gigi.",
    "IndikasiUmum": "Kalsium tambahan pada masa pertumbuhan, masa hamil, menyusui & untuk pertumbuhan tulang dan gigi",
    "Komposisi": "Kalsium Laktat",
    "Dosis": "Dewasa: 0,5–4 g per hari, dibagi dalam 1–3 dosis.\nAnak-anak: 0,5–1 g per hari.",
    "AturanPakai": "Sesudah Makan",
    "KontraIndikasi": "Penderita hiperkalemia dan hiperkalsiurea",
    "Perhatian": "Insufisiensi ginjal atau yang menderita batu ginjal",
    "EfekSamping": "mual, muntah, kehilangan napsu makan, sembelit, tenggorokan kering",
    "KategoriKehamilan": "Kategori A: Pada wanita hamil tidak menunjukkan adanya risiko terhadap janin, dan kecil kemungkinannya untuk membahayakan janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 72,
    "Kategori": "Multivitamin",
    "NamaObat": "TABLET TAMBAH DARAH",
    "Kemasan": "",
    "BentukObat": "Tablet",
    "Golongan": "Obat Bebas",
    "GambarObat": img_72,
    "Deskripsi": "TABLET TAMBAH DARAH merupakan tablet salut gula yang mengandung zat besi dan asam folat. Zat besi penting dalam pembentukan hemoglobin ditubuh sehingga dapat membantu mengatasi anemia saat menstruasi, hamil, menyusui, masa pertumbuhan, dan setelah mengalami pendarahan. Asam folat digunakan untuk mengurangi anemia megaloblastik selama kehamilan dan masa pertumbuhan.",
    "IndikasiUmum": "Membantu memenuhi kebutuhan zat besi dan asam folat tubuh, serta mengatasi anemia megaloblastik.",
    "Komposisi": "Ferrous Fumarate 60 mg, Asam Folat 400 mcg",
    "Dosis": "Dewasa : 1 tablet 1 kali sehari",
    "AturanPakai": "Ferrous Fumarate (zat besi) diserap baik saat perut kosong, tetapi dapat diminum bersamaan dengan makanan jika terjadi gejala mual. Tablet diminum dengan segelas penuh air mineral (240 ml). Tidak disarankan berbaring setelah minum tablet ini (setidaknya 30 menit setelah konsumsi tablet).",
    "KontraIndikasi": "Haemosiderosis, haemochromatosis, ulkus peptikum, inflammatory bowel disease. Penggunaan bersama dimercaprol dan atau parenteral Fe.",
    "Perhatian": "Simpan pada suhu di bawah 30 celcius, dalam wadah tertutup rapat, terlindung dari cahaya dan kelembaban.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Feses berwarna gelap, mual, muntah, konstipasi.",
    "KategoriKehamilan": "Kategori A: Pada wanita hamil tidak menunjukkan adanya risiko terhadap janin, dan kecil kemungkinannya untuk membahayakan janin.",
    "CaraTepatPenggunaan": ""
  },
  {
    "No": 73,
    "Kategori": "Obat Mata",
    "NamaObat": "KLORAMFENIKOL (RECO)",
    "Kemasan": "0.5%",
    "BentukObat": "Tetes Mata",
    "Golongan": "Obat Keras",
    "GambarObat": img_73,
    "Deskripsi": "Reco adalah sediaan obat dalam bentuk tetes mata yang diproduksi oleh Global Multi Pharmalab. Reco Tetes Mata digunakan untuk mengobati infeksi pada mata akibat bakteri, misalnya peradangan mata (konjungtivitis), iritis, uvetis dan infeksi okular. Reco mengandung zat aktif Chloramphenicol.",
    "IndikasiUmum": "Konjungtivitis bakterial dan infeksi mata/okular superfisial lainnya",
    "Komposisi": "Chloramphenicol 0.5%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Awal 3-4 kali sehari 2 tetes",
    "AturanPakai": "Teteskan Pada Mata yang sakit",
    "KontraIndikasi": "Hipersensitif",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. Pertumbuhan berlebihan dari organisme yang tidak peka termasuk jamur (penggunaan jangka lama)",
    "EfekSamping": "Reaksi alergik, superinfeksi",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Obat tetes Mata"
  },
  {
    "No": 74,
    "Kategori": "Obat Mata",
    "NamaObat": "KLORAMFENIKOL",
    "Kemasan": "1%",
    "BentukObat": "Salep Mata",
    "Golongan": "Obat Keras",
    "GambarObat": img_74,
    "Deskripsi": "CHLORAMFENICOL 1% SALEP MATA mengandung zat aktif Chloramfenicol yang merupakan antibiotik dengan spektrum luas, memiliki aktivitas bakteriostatik yang efektif terhadap berbagai organisme gram positif dan gram negatif. Salep mata ini digunakan untuk mengatasi masalah mata seperti Trachoma, keratitis (peradangan selaput kornea mata), konjungtivitis (peradangan selaput ikat mata), dakriosistitis (peradangan kantung air mata) dan uveitis (peradangan lapisan mata yang terdiri dari iris, badan ciliaris dan koroid). Dalam penggunaan obat ini HARUS SESUAI DENGAN PETUNJUK DOKTER.",
    "IndikasiUmum": "Mengatasi masalah mata seperti Trachoma, keratitis (peradangan selaput kornea mata), konjungtivitis (peradangan selaput ikat mata), dakriosistitis (peradangan kantung air mata) dan uveitis (peradangan lapisan mata yang terdiri dari iris, badan ciliaris dan koroid).",
    "Komposisi": "CHLORAMFENICOL 1%",
    "Dosis": "PENGGUNAAN OBAT INI HARUS SESUAI DENGAN PETUNJUK DOKTER. Oleskan 3 kali sehari",
    "AturanPakai": "Oleskan pada bagian dalam kantung mata",
    "KontraIndikasi": "Hipersensitivitas. Kehamilan dan menyusui.",
    "Perhatian": "HARUS DENGAN RESEP DOKTER. \n- Hindari penggunaan jangka panjang. \n- Tidak diindikasikan untuk pengobatan infeksi virus atau profilaksis infeksi bakteri. \n- Anak-anak. \n- Kehamilan dan menyusui.",
    "EfekSamping": "Pemakaian obat umumnya memiliki efek samping tertentu dan sesuai dengan masing-masing individu. Jika terjadi efek samping yang berlebih dan berbahaya, harap konsultasikan kepada tenaga medis. Efek samping yang mungkin terjadi dalam penggunaan obat adalah: Perih dan iritasi sementara (oftalmik), neuritis optik (penggunaan lama).",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Salep Mata"
  },
  {
    "No": 75,
    "Kategori": "Obat Telinga",
    "NamaObat": "KLORAMFENIKOL (RECO)",
    "Kemasan": "1%",
    "BentukObat": "Tetes Telinga",
    "Golongan": "Obat Keras",
    "GambarObat": img_75,
    "Deskripsi": "Reco mengandung antibiotik Kloramfenikol 1% yang bekerja dengan  menghambat sintesis protein bakteri dengan mengikat subunit 50 dari ribosom bakteri, sehingga mencegah pembentukan ikatan peptida oleh peptidil transferase. Reco Tetes Telinga digunakan untuk mengobati infeksi pada saluran telinga bagian luar.",
    "IndikasiUmum": "untuk mengobati infeksi pada saluran telinga bagian luar (otitis externa).",
    "Komposisi": "Kloramfenikol 1%",
    "Dosis": "teteskan 3-4 tetes ke telinga yang sakit sebanyak 2-3 kali sehari selama 1 minggu.",
    "AturanPakai": "Teteskan Pada Telinga yang sakit",
    "KontraIndikasi": "- Riwayat hipersensitivitas atau reaksi toksik terhadap kandungan dalam produk, \n- Gendang telinga berlubang",
    "Perhatian": "",
    "EfekSamping": "Ototoksisitas (penurunan fungsi dan kerusakan seluler di telinga bagian dalam)",
    "KategoriKehamilan": "Kategori C: Obat hanya boleh digunakan jika besarnya manfaat yang diharapkan melebihi besarnya risiko terhadap janin.",
    "CaraTepatPenggunaan": "Obat tetes Telinga"
  }
]

export default db_obat;