import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import {  Switch, Route, useRouteMatch } from 'react-router-dom';
import ButtonGroup from '../components/ButtonGroup';
import ListObat from '../components/ListObat';
import DetailObat from '../components/DetailObat';

function Direktori_obat_page() {

  let { path} = useRouteMatch();

  const printButtonLabel = (event) => {
    console.log(event.target.name);
    //do some stuff here
  };
  

  return (
    <>
      <div style={{ width: 'auto', height: 'auto', backgroundColor: 'rgba(246, 244, 242, 1)' }}>
        <Container xl>
          <Box sx={{ pt: '30px' }} />
          <Typography sx={{ fontSize: 65, fontWeight: 700, textAlign: 'center', color: 'rgba(31, 161, 158, 1)' }}>Direktori & Informasi Obat</Typography>
          <Typography sx={{ fontSize: 40, fontWeight: 300, textAlign: 'center', color: 'rgba(0,0,0, 1)' }}>UPT Puskesmas Pamulang</Typography>
          <Typography sx={{ fontSize: 22, fontWeight: 300, textAlign: 'center', color: 'rgba(0,0,0, 1)' }}>disusun oleh Tim Farmasi Apotek UPT Puskesmas Pamulang</Typography>
          <Box sx={{ pt: '60px' }} />
          <Grid container direction={'row'}>
            <Grid item direction={'column'} xs={3.8} sx={{ height: 'auto' }}>
              <ButtonGroup doSomeThing={printButtonLabel} />
            </Grid>
            <Grid item direction={'column'} xs={8.2} sx={{ height: 'auto' }}>
              <Switch>
                <Route exact path={`${path}/:kategori`}>
                  <ListObat />
                </Route>
                <Route  path={`${path}/:kategori/:id`}>
                  <DetailObat />
                </Route>
              </Switch>
            </Grid>
          </Grid>
          <Box sx={{ pt: '60px' }} />
        </Container>
      </div>
    </>
  );
}

export default Direktori_obat_page;
