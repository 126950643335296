import ic_antivirus from '../assets/images/iconKategoriObat/Ic-Antivirus.svg';
import ic_antibiotik from '../assets/images/iconKategoriObat/Ic-Antibiotik.svg';
import ic_multivitamin from '../assets/images/iconKategoriObat/Ic-Multivitamin.svg';
import ic_antijamur_antiparasit from '../assets/images/iconKategoriObat/Ic-Antijamur-Antiparasit.svg';
import ic_obat_demam_nyeri from '../assets/images/iconKategoriObat/Ic-Obat-Demam-Nyeri.svg';
import ic_obat_pilek from '../assets/images/iconKategoriObat/Ic-Obat-Pilek.svg';
import ic_obat_batuk from '../assets/images/iconKategoriObat/Ic-Obat-Batuk.svg';
import ic_obat_asam_urat from '../assets/images/iconKategoriObat/Ic-Obat-Asam-Urat.svg';
import ic_obat_darah_tinggi from '../assets/images/iconKategoriObat/Ic-Obat-Darah-Tinggi.svg';
import ic_obat_diabetes from '../assets/images/iconKategoriObat/Ic-Obat-Diabetes.svg';
import ic_obat_diare from '../assets/images/iconKategoriObat/Ic-Obat-Diare.svg';
import ic_obat_jantung from '../assets/images/iconKategoriObat/Ic-Obat-Jantung.svg'; 
import ic_obat_kolesterol from '../assets/images/iconKategoriObat/Ic-Obat-Kolesterol.svg'; 
import ic_obat_lambung from '../assets/images/iconKategoriObat/Ic-Obat-Lambung.svg'; 
import ic_obat_mata from '../assets/images/iconKategoriObat/Ic-Obat-Mata.svg'; 
import ic_obat_muntah from '../assets/images/iconKategoriObat/Ic-Obat-Muntah.svg'; 
import ic_obat_pengencer_darah from '../assets/images/iconKategoriObat/Ic-Obat-Pengencer-Darah.svg'; 
import ic_obat_radang from '../assets/images/iconKategoriObat/Ic-Obat-Radang.svg'; 
import ic_obat_sesak_napas from '../assets/images/iconKategoriObat/Ic-Obat-Sesak-Napas.svg'; 
import ic_obat_telinga from '../assets/images/iconKategoriObat/Ic-Obat-Telinga.svg'; 


const kategori_obat = 
[
  {
    name: 'Antivirus',
    url: 'antivirus',
    image: ic_antivirus,
  },
  {
    name: 'Antibiotik',
    url: 'antibiotik',
    image: ic_antibiotik,
  },
  {
    name: 'Antijamur & Antiparasit',
    url: 'antijamur-antiparasit',
    image: ic_antijamur_antiparasit,
  },
  {
    name: 'Obat Demam, Nyeri, & Pusing',
    url: 'obat-demam-nyeri',
    image: ic_obat_demam_nyeri,
  },
  {
    name: 'Obat Alergi/Pilek',
    url: 'obat-pilek',
    image: ic_obat_pilek,
  },
  {
    name: 'Obat Batuk',
    url: 'obat-batuk',
    image: ic_obat_batuk,
  },
  {
    name: 'Obat Radang',
    url: 'obat-radang',
    image: ic_obat_radang,
  },
  {
    name: 'Obat Sesak Napas',
    url: 'obat-sesak-napas',
    image: ic_obat_sesak_napas,
  },
  {
    name: 'Obat Diare',
    url: 'obat-diare',
    image: ic_obat_diare,
  },
  {
    name: 'Obat Asam Urat',
    url: 'obat-asam-urat',
    image: ic_obat_asam_urat,
  },
  {
    name: 'Obat Darah Tinggi',
    url: 'obat-darah-tinggi',
    image: ic_obat_darah_tinggi,
  },
  {
    name: 'Obat Diabetes',
    url: 'obat-diabetes',
    image: ic_obat_diabetes,
  },
  {
    name: 'Obat Jantung',
    url: 'obat-jantung',
    image: ic_obat_jantung,
  },
  {
    name: 'Obat Pengencer Darah',
    url: 'obat-pengencer-darah',
    image: ic_obat_pengencer_darah,
  },
  {
    name: 'Obat Lambung',
    url: 'obat-lambung',
    image: ic_obat_lambung,
  },
  {
    name: 'Obat Kolesterol',
    url: 'obat-kolesterol',
    image: ic_obat_kolesterol,
  },
  {
    name: 'Obat Mual/Muntah',
    url: 'obat-muntah',
    image: ic_obat_muntah,
  },
  {
    name: 'Multivitamin',
    url: 'multivitamin',
    image: ic_multivitamin,
  },
  {
    name: 'Obat Mata',
    url: 'obat-mata',
    image: ic_obat_mata,
  },
  {
    name: 'Obat Telinga',
    url: 'obat-telinga',
    image: ic_obat_telinga,
  },
]

export default kategori_obat;
