import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile';
import Header from './components/Header';
import HeaderMobile from './components/HeaderMobile';
import Beranda from './pages/Beranda_page';
import DirektoriObat from './pages/Direktori_obat_page';
import DirektoriObatMobile from './pages/Direktori_obat_mobile_page';
import Faq from './pages/Faq_page';
import FaqMobile from './pages/Faq_mobile_page';
import TanyaApoteker from './pages/Tanya_apoteker_page';
import TanyaApotekerMobile from './pages/Tanya_apoteker_mobile_page';
import Media from 'react-media';

function App() {
  return (
    <>
      <Media
        queries={{
          mobile: '(max-width: 599px)',
          desktop: '(min-width: 600px)',
        }}>
        {(matches) => (
          <>
            <Router>
              {matches.mobile && <HeaderMobile />}
              {matches.desktop && <Header />}

              <Switch>
                <Route path='/' exact>
                  <Beranda />
                </Route>
                <Route path='/direktori-obat'>
                  {matches.desktop && <DirektoriObat />}
                  {matches.mobile && <DirektoriObatMobile />}
                  </Route>
                <Route path='/faq'>
                  {matches.mobile && <FaqMobile />}
                  {matches.desktop && <Faq />}
                  </Route>
                <Route path='/tanya-apoteker'>
                  {matches.mobile && <TanyaApotekerMobile />}
                  {matches.desktop && <TanyaApoteker />}
                  </Route>
              </Switch>

              {matches.mobile && <FooterMobile />}
              {matches.desktop && <Footer />}
            </Router>
          </>
        )}
      </Media>
    </>
  );
}

export default App;
